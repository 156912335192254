// @flow

import * as React from "react";
import { useState, useEffect, useFocus, useRef } from "react";
import { useHistory } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { Page, Grid, Card, Table, Alert, Progress, Button, Form } from "tabler-react";
import { useSelector } from 'react-redux'
import UptimeCard from "../components/UptimeCard";
import { Formik, Field } from "formik";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import SiteWrapper from "../SiteWrapper";
import * as stationService from "../store/services/stationsServices";
import * as companyService from "../store/services/companyServices";
import * as billService from "../store/services/billServices";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GoogleMap from "../GoogleMap";
import Moment from "react-moment";
import CustomSelect from "../components/select";
import Svgicon from "../components/SvgIcon";
import { FRONTENDURL, SOCKET_URL } from "../store/services/type";

import WebSocketClient from "../components/WebSocketClient";
const formSchema = Yup.object().shape({
  station_id: Yup.string().required("Charge point ID is required"),
  // company: Yup.string().required("Company is required"),
  company: Yup.string(),
  price_per_kwh: Yup.number().required("Price per kwh is required"),
  // site: Yup.string().required("Site is required"),
  site: Yup.string(),
  city: Yup.string().required("Region is required"),
  postcode: Yup.string().required("Postcode is required"),
  type: Yup.string().required("Access Type is required"),
  connection_fee: Yup.number().required("Connection Fee is required"),
  street: Yup.string().required("Address is required"),
  connector_type: Yup.string().required("Connector Type is required"),
  connectors: Yup.number().required("Connectors is required"),
});
const notesSchema = Yup.object().shape({
  // notes: Yup.string().required("Notes is required"),
});
function StationsNotes(props) {
  const [message, setMessage] = useState({});
  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.notes}
      validationSchema={notesSchema}
      onSubmit={(values, actions) => {
        setMessage({});
        props.handleUpdateNotes(values).then((message) => {
          if ((message.type = "success")) {
            actions.setSubmitting(false);
            setMessage(message);
            setTimeout(() => {
              setMessage({});
            }, 5000);
          } else {
            setMessage(message);
          }
        });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} autoComplete='off'>
          <Card.Body className='pt-0'>
            <Grid.Row>
              <Grid.Col>
                <textarea
                  rows='5'
                  placeholder='Type notes here…'
                  name='notes'
                  className={errors.notes && touched.notes ? "w-100 form-control notes-area  is-invalid" : "w-100 form-control notes-area"}
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
                {errors.notes && touched.notes && <div className='invalid-feedback'>{errors.notes}</div>}
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className='mt-4'>
                {message.message && <Alert type={message.type}>{message.message}</Alert>}
                <Button color='secondary' type='submit' disabled={!values.notes || isSubmitting}>
                  Save
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        </form>
      )}
    </Formik>
  );
}
function SingleChargePoint(props) {
  const [filterParams, setParams] = useState({
    page: 1,
  });
  const [totalPage, setTotalPage] = useState(1);
  const [totalTransaction, setTransactionCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [alert, setAlert] = useState({});
  const [stationData, setStationData] = useState({
    station_id: "",
    company: "",
    site: "",
    price_per_kwh: "",
    connection_fee: "",
    city: "",
    postcode: "",
    connector_type: "",
    street: "",
    street2: "",
    total_charge_time: "",
  });
  const [transactions, setTransactions] = useState([]);


  const [logParams, setLogParams] = useState({
    page: 1,
  });
  const [totalLogPage, setTotalLogPage] = useState(1);
  const [currentLogPage, setCurrentLogPage] = useState(1);
  const [logs, setLogs] = useState([]);
  const [custom, setCustoms] = useState([]);


  const [activeStations, setActiveStations] = useState([]);
  const [notifications, setNotifications] = useState({});
  const [conneted, setConnected] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const { user } = useSelector(state => state.auth);

  const options = (
    <React.Fragment>
      <Button
        color='secondary'
        onClick={() => {
          props.history.push("/charge-points");
        }}
      >
        Back
      </Button>
    </React.Fragment>
  );
  const convertHours = (value) => {
    var n = new Date(0, 0);
    // n.setSeconds(+value * 60 * 60);
    n.setSeconds(+value);
    return n.toTimeString().slice(0, 5);
  };
  const handleUpdateNotes = (values) => {
    return new Promise((resolve) => {
      stationService
        .updateStationNotes(props.match.params.id, values)
        .then((response) => {
          resolve({
            type: "success",
            message: "Successfully updated notes",
          });
        })
        .catch((error) => {
          resolve({
            type: "danger",
            message: (error.response && error.response.data.message) || error.message,
          });
        });
    });
  };
  useEffect(() => {
    if (props.match.params.id) {
      setPageLoading(true);
      stationService
        .getStationsById(props.match.params.id, filterParams)
        .then((response) => {
          setStatus(response.data.info);
          setStationData(response.data.station);
          setTransactionCount(response.data.station.transactions.total);
          setTotalPage(Math.ceil(response.data.station.transactions.total / 10));
          setCurrentPage(response.data.station.transactions.current_page);
          setTransactions(response.data.station.transactions.data);
          setCustoms(response.data.custom);
          setPageLoading(false);
        })
        .catch((error) => {
          setAlert({
            type: "danger",
            message: (error.response && error.response.data.message) || error.message,
          });
          setPageLoading(false);
        });
    }
  }, [filterParams]);
  useEffect(() => {
    let index = activeStations.findIndex((station) => parseInt(station.id) === parseInt(props.match.params.id));
    if (index > -1) {
      setConnected(true);
    } else {
      setNotifications({});
      setConnected(false);
    }
    if (activeStations.length === 0) {
      setConnected(false);
      setNotifications({});
    }
  }, [activeStations]);

  useEffect(() => {
    if (props.match.params.id) {
      setPageLoading(true);
      stationService.getLogs(props.match.params.id, logParams)
        .then(res => {
          setTotalLogPage(Math.ceil(res.data.logs.total / 10));
          setCurrentLogPage(res.data.logs.current_page);
          setLogs(res.data.logs.data);
          setPageLoading(false);
        })
        .catch(err => { setPageLoading(false); })
    }
  }, [logParams])

  const handleNotification = (notifications) => {
    setNotifications(notifications);
    console.log(notifications)
    if (props.match.params.id && notifications.stationId === Number(props.match.params.id))
      setStatus(notifications.message);
  };

  const connectorData = (i) => {
    let connectors = activeStations.filter((s) => s.id === stationData.id && s.connected);
    let connector = connectors.find((c) => c.notification && Number(c.notification.connectorId) === i);
    return connector;
  };
  const showConnectors = () => {
    let view = [];
    for (let i = 1; i < stationData.connectors + 1; i++) {
      view.push(
        <Grid.Row className='mb-5'>
          <Grid.Col>
            <div className='subheader mb-3'>CONNECTOR-{i}</div>
            <div className='details'>{connectorData(i) ? connectorData(i).notification.status : "---"}</div>
          </Grid.Col>
          <Grid.Col>
            <div className='subheader mb-3'>
              <span style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    backgroundColor: connectorData(i) ? "#6fd758" : "#ff4747",
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    marginRight: 10,
                  }}
                ></span>
                {connectorData(i) ? "Connected" : "Disconnected"}
              </span>
            </div>
            <div className='details'>{connectorData(i) ? connectorData(i).notification.errorCode : "---"}</div>
          </Grid.Col>
        </Grid.Row>
      );
    }
    return view;
  };

  const clientRef = useRef(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null);
  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }
    if (!clientRef.current) {
      const client = new WebSocket(SOCKET_URL);
      clientRef.current = client;
      window.client = client;
      client.onerror = (e) => console.error(e);
      client.onopen = () => {
        // client.send("ping");
      };
      client.onclose = () => {
        if (clientRef.current) {
          setActiveStations([]);
        } else {
          return;
        }
        if (waitingToReconnect) {
          return;
        }
        setWaitingToReconnect(true);
        setTimeout(() => setWaitingToReconnect(null), 5000);
      };
      client.onmessage = (message) => {
        console.log('++++ stations', JSON.parse(message.data))
        if (JSON.parse(message.data).stations) {
          let data = JSON.parse(message.data).stations.filter((s) => s.connected);
          setActiveStations(data);
          // setActiveStations(JSON.parse(message.data).stations);
        }
      };
      return () => {
        clientRef.current = null;
        client.close();
      };
    }
  }, [waitingToReconnect]);

  return (
    <SiteWrapper loading={pageLoading}>
      <Page.Content>
        <Page.Header title='Charge Points' options={options} />
        <Grid.Row cards={true}>
          <Grid.Col width={12} lg={6}>
            <Card className=' h-100'>
              <Card.Header className='d-flex'>
                <Svgicon name='user' />
                <Card.Title className='d-flex'>
                  Details
                  {stationData.connectors === 1 &&
                    <div style={{ marginLeft: 30, fontSize: 12, fontWeight: 500, display: "flex" }}>
                      {conneted ? (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{
                              backgroundColor: "#6fd758",
                              width: 10,
                              height: 10,
                              borderRadius: "50%",
                              marginRight: 10,
                            }}
                          ></span>
                          Connected
                        </span>
                      ) : (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{
                              backgroundColor: "#ff4747",
                              width: 10,
                              borderRadius: "50%",
                              height: 10,
                              marginRight: 10,
                            }}
                          ></span>
                          Disconnected
                        </span>
                      )}
                      {conneted && parseInt(notifications.stationId) === parseInt(props.match.params.id) && (
                        <span style={{ marginLeft: 10 }}>{notifications.message.status}</span>
                      )}
                    </div>}
                </Card.Title>
              </Card.Header>
              {/* <div className='card-body cp-new-body'>
                {conneted && status && (
                  <Grid.Row className='mb-5'>
                    <Grid.Col>
                      <div className='subheader mb-3'>STATUS</div>
                      <div className='details'>
                        {status.connectorId} - {status.status}
                      </div>
                    </Grid.Col>
                    <Grid.Col>
                      <div className='subheader mb-3'>ERROR CODE</div>
                      <div className='details'>
                        {status.connectorId} - {status.errorCode}
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                )}
                <Grid.Row className='mb-5'>
                  <Grid.Col>
                    <div className='subheader mb-3'>LOCATION</div>
                    <div className='details'>
                      {stationData.street +
                        " " +
                        (stationData.street2 ? stationData.street2 : "") +
                        " " +
                        stationData.city +
                        " " +
                        stationData.postcode}
                    </div>
                  </Grid.Col>
                  <Grid.Col>
                    <div className='subheader mb-3'>ID</div>
                    <div className='details'>{stationData.id}</div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className='mb-5'>
                  <Grid.Col>
                    <div className='subheader mb-3'>TARIFF</div>
                    <div className='details'>£{parseFloat(stationData.price_per_kwh).toFixed(2)} per kwh</div>
                  </Grid.Col>
                  <Grid.Col>
                    <div className='subheader mb-3'>CONNECTION FEE</div>
                    <div className='details'>£{parseFloat(stationData.connection_fee).toFixed(2)}</div>
                  </Grid.Col>
                </Grid.Row>
                
                <Grid.Row className='mb-5'>
                  <Grid.Col>
                    <div className='subheader mb-3'>ACCESS LEVEL</div>
                    <div className='details'>{stationData.type ? 'Private' : 'Public'}</div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Button
                      color='secondary'
                      onClick={() => {
                        props.history.push("/charge-points/edit/" + props.match.params.id);
                      }}
                    >
                      Edit
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </div> */}
              <Card.Body>
                {conneted && status && stationData.connectors === 1 && (
                  <Grid.Row className='mb-5'>
                    <Grid.Col>
                      <div className='subheader mb-3'>STATUS</div>
                      {/* <div className='details'>{status.status}</div> */}
                      <div className='details'>{connectorData(1) ? connectorData(1).notification.status : "---"}</div>
                    </Grid.Col>
                    <Grid.Col>
                      <div className='subheader mb-3'>ERROR CODE</div>
                      {/* <div className='details'>{status.errorCode}</div> */}
                      <div className='details'>{connectorData(1) ? connectorData(1).notification.errorCode : "---"}</div>
                    </Grid.Col>
                  </Grid.Row>
                )}
                {stationData.connectors > 1 && showConnectors()}
                <Grid.Row className='mb-5'>
                  <Grid.Col>
                    <div className='subheader mb-3'>LOCATION</div>
                    <div className='details'>
                      {stationData.street +
                        " " +
                        (stationData.street2 ? stationData.street2 : "") +
                        " " +
                        stationData.city +
                        " " +
                        stationData.postcode}
                    </div>
                  </Grid.Col>
                  <Grid.Col>
                    <div className='subheader mb-3'>ID</div>
                    <div className='details'>{stationData.id}</div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className='mb-5'>
                  <Grid.Col>
                    <div className='subheader mb-3'>TARIFF</div>
                    <div className='details'>£{parseFloat(stationData.price_per_kwh || 0).toFixed(2)} per kwh</div>
                  </Grid.Col>
                  <Grid.Col>
                    <div className='subheader mb-3'>CONNECTION FEE</div>
                    <div className='details'>£{parseFloat(stationData.connection_fee || 0).toFixed(2)}</div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className='mb-5'>
                  <Grid.Col>
                    <div className='subheader mb-3'>ACCESS LEVEL</div>
                    <div className='details'>{stationData.type === 'prt' ? 'Private' : stationData.type === 'h' ? 'Home' : 'Public'}</div>
                  </Grid.Col>
                  {user && user.type === "Super" &&
                    <Grid.Col>
                      <div className='subheader mb-3'>Account id</div>
                      <div className='details'>{stationData.account_id}</div>
                    </Grid.Col>}
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Button
                      color='secondary'
                      onClick={() => {
                        // if (stationData.type === "p") {
                        props.history.push("/charge-points/edit/" + props.match.params.id);
                        // } else {
                        //   props.history.push("/charge-points/" + props.match.params.id);
                        // }
                      }}
                    >
                      Edit
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>
          </Grid.Col>
          <UptimeCard id={props.match.params.id} nopercent />
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12} lg={6} className='details-card'>
            <Grid.Row>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className='text-center'>
                    <div className='h1 m-3'>{totalTransaction}</div>
                    <div className='mb-3' style={{ color: '#74797e' }}>Transactions</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className='text-center'>
                    <div className='h1 m-3'>
                      {stationData.total_energy_used && parseFloat(stationData.total_energy_used.toFixed(2) * 100) / 100}
                      kwh
                    </div>
                    <div className='mb-3' style={{ color: '#74797e' }}>Total energy used</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className='text-center'>
                    <div className='h1 m-3'>£{stationData.revenue && (stationData.revenue / 100).toFixed(2)}</div>
                    <div className='mb-3' style={{ color: '#74797e' }}>Revenue</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className='text-center padding-sm-0'>
                    <div className='h1 m-3'>{convertHours(stationData.total_charge_time)}</div>
                    <div className='mb-3' style={{ color: '#74797e' }}>Total charge time (hrs)</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col width={12} lg={6}>
            <Card>
              <Card.Header>
                <Svgicon name='notes' />
                <Card.Title>Notes</Card.Title>
              </Card.Header>
              <StationsNotes notes={{ notes: stationData.notes }} handleUpdateNotes={handleUpdateNotes} />
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Card className={custom.length === 0 ? 'user-fixed-card' : ''}>
              <Card.Header>
                <Svgicon name='user' />
                <Card.Title>Users</Card.Title>
              </Card.Header>
              {custom.length > 0 &&
                <Table cards={true} striped={true} responsive={true} className='table card-table table-vcenter datatable border-bottom-0'>
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>Name</Table.ColHeader>
                      <Table.ColHeader>Role</Table.ColHeader>
                      <Table.ColHeader>CONNECTION FEE</Table.ColHeader>
                      <Table.ColHeader>Tariff</Table.ColHeader>
                      <Table.ColHeader></Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {custom.map((item, i) => (
                      <Table.Row key={i}>
                        <Table.Col className='user-name'>{item.user.name}</Table.Col>
                        <Table.Col>{item.user.type}</Table.Col>
                        <Table.Col>£{(item.fee).toFixed(2)}</Table.Col>
                        <Table.Col>£{(item.price).toFixed(2)}</Table.Col>
                        <Table.Col>
                          <Button
                            color='secondary'
                            onClick={() => {
                              props.history.push("/users/assign/" + item.user.id + "/" + item.id);
                            }}
                          >
                            Edit
                          </Button>
                        </Table.Col>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              }
              {custom.length === 0 &&
                <Card.Body className='align-items-center justify-content-center'>
                  <h4 className="h4 mt-4 text-center" style={{ color: '#000', marginBottom: 20 }}>
                    No Users
                  </h4>
                  <div className="detail mb-5">You haven't added any users to this charge point</div>
                </Card.Body>
              }

            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Card className={transactions.length === 0 ? 'user-fixed-card' : ''}>
              <Card.Header>
                <Svgicon name='transaction' />
                <Card.Title>Transactions</Card.Title>
              </Card.Header>
              {transactions.length > 0 &&
                <Table cards={true} striped={true} responsive={true} className='table card-table table-vcenter datatable'>
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>ID</Table.ColHeader>
                      <Table.ColHeader>Name</Table.ColHeader>
                      <Table.ColHeader>Date</Table.ColHeader>
                      <Table.ColHeader>Charge</Table.ColHeader>
                      <Table.ColHeader>Energy used</Table.ColHeader>
                      <Table.ColHeader>Price</Table.ColHeader>
                      <Table.ColHeader>Transaction ID</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {transactions.map((item, i) => (
                      <Table.Row key={i}>
                        <Table.Col>{item.id}</Table.Col>
                        <Table.Col className='text-nowrap'>{item.user.name}</Table.Col>
                        <Table.Col>
                          <Moment format='HH:mm DD MMM YYYY'>{item.charge_date}</Moment>
                        </Table.Col>
                        <Table.Col>{convertHours(item.charging_time)}</Table.Col>
                        <Table.Col>{parseFloat(item.total_kwh.toFixed(2) * 100) / 100}kwh</Table.Col>
                        <Table.Col className='price'>£{(item.charge_cost / 100).toFixed(2)}</Table.Col>
                        <Table.Col>{item.charge_id}</Table.Col>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              }
              {transactions.length === 0 &&
                <Card.Body className='align-items-center justify-content-center'>
                  <h4 className="h4 mt-4 text-center" style={{ color: '#000', marginBottom: 20 }}>
                    No Transactions
                  </h4>
                  <div className="detail mb-5">You haven’t made any transactions yet.</div>
                </Card.Body>
              }
              {transactions.length > 0 && (
                <div className='pagination-barlows'>
                  <Button
                    color='secondary'
                    onClick={() => {
                      setParams({
                        ...filterParams,
                        ...{ page: currentPage - 1 },
                      });
                    }}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </Button>

                  <span>
                    {currentPage} of {totalPage}
                  </span>
                  <Button
                    color='secondary'
                    onClick={() => {
                      setParams({
                        ...filterParams,
                        ...{ page: currentPage + 1 },
                      });
                    }}
                    disabled={currentPage === totalPage}
                  >
                    Next
                  </Button>
                </div>
              )}
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Card className={logs.length === 0 ? 'user-fixed-card' : ''}>
              <Card.Header>
                <Svgicon name='log' />
                <Card.Title>Logs</Card.Title>
              </Card.Header>
              {logs.length > 0 &&
                <Table cards={true} striped={true} responsive={true} className="table card-table table-vcenter datatable">
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>ID</Table.ColHeader>
                      <Table.ColHeader>Date</Table.ColHeader>
                      <Table.ColHeader>Log</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {logs.map((item, i) => (
                      <Table.Row key={i}>
                        <Table.Col className='log-id'><div>{item.id}</div></Table.Col>
                        <Table.Col className='log-date'>
                          <Moment format="YYYY-MM-DD HH:mm">{item.created_at}</Moment>
                        </Table.Col>
                        <Table.Col className='log-message'>
                          <div>
                            {item.message}
                          </div>
                        </Table.Col>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              }
              {logs.length === 0 &&
                <Card.Body className='align-items-center justify-content-center'>
                  <h4 className="h4 mt-4 text-center" style={{ color: '#000', marginBottom: 20 }}>
                    No logs found
                  </h4>
                  <div className="detail mb-5">There are no any logs yet.</div>
                </Card.Body>
              }
              {logs.length > 0 && (
                <div className="pagination-barlows">
                  <Button
                    color="secondary"
                    onClick={() => {
                      setLogParams({
                        ...logParams,
                        ...{ page: currentLogPage - 1 },
                      });
                    }}
                    disabled={currentLogPage === 1}
                  >
                    Prev
                  </Button>

                  <span>
                    {currentLogPage} of {totalLogPage}
                  </span>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setLogParams({
                        ...logParams,
                        ...{ page: currentLogPage + 1 },
                      });
                    }}
                    disabled={currentLogPage === totalLogPage}
                  >
                    Next
                  </Button>
                </div>
              )}
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
      <WebSocketClient setActiveStations={setActiveStations} setNotifications={handleNotification} />
    </SiteWrapper>
  );
}

function AddNewChargePoint(props) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [resetAlert, setResetAlert] = useState({});
  const [firmwareAlert, setFirmwareAlert] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [resetType, setResetType] = useState("");
  const [firmLocation, setFirmLocation] = useState("");
  const [errorReset, setErrorResetType] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [initialValues, setInitialValues] = useState({
    station_id: "",
    company: "",
    site: "",
    price_per_kwh: "",
    connection_fee: "",
    city: "",
    postcode: "",
    connector_type: "",
    street: "",
    street2: "",
    type: '0',
    connectors: 1
  });
  const handleSubmit = (values) => {
    // console.log(values); return
    values.type = values.type;
    setPageLoading(true);
    if (values.id) {
      delete values.name;
      delete values.created_at;
      delete values.updated_at;
      delete values.transactions;
      delete values.revenue;
      delete values.total_charge_time;
      delete values.total_energy_used;
      stationService
        .updateStation(values.id, values)
        .then((response) => {
          setPageLoading(false);
          setAlert({
            type: "success",
            message: "Successfully updated changed data",
          });
          window.scrollTo(0, 0)
        })
        .catch((error) => {
          setAlert({
            type: "danger-chargebox",
            message: (error.response && error.response.data.message) || error.message,
          });
          setPageLoading(false);
          window.scrollTo(0, 0)
        });
    } else {
      stationService
        .createStation(values)
        .then((response) => {
          setPageLoading(false);
          history.push("/charge-points");
        })
        .catch((error) => {
          setAlert({
            type: "danger-chargebox",
            message: (error.response && error.response.data.message) || error.message,
          });
          setPageLoading(false);
        });
    }
  };
  const handleReset = () => {
    if (resetType) {
      setErrorResetType(false);
      stationService
        .RemoteReset(props.match.params.id, resetType)
        .then((response) => {
          setResetAlert({
            type: "success",
            message: "Successfully reseted charge point",
          });
        })
        .catch((error) => {
          setResetAlert({
            type: "danger",
            message: (error.response && error.response.data.message) || error.message,
          });
        });
    } else {
      setErrorResetType(true);
    }
  };
  const handleUpdateFirmware = () => {
    if (firmLocation) {
      stationService
        .updateFirmware(props.match.params.id, { location: firmLocation })
        .then((response) => {
          setFirmwareAlert({
            type: "success",
            message: "Please wait for update firmware",
            // message: "Successfully updated firmware",
          });
        })
        .catch((error) => {
          setFirmwareAlert({
            type: "danger",
            message: (error.response && error.response.data.message) || error.message,
          });
        });
    } else {
      setErrorLocation(true);
    }
  };
  const deleteChargePoint = () => {
    handleClose();
    // console.log(initialValues.id); return
    setPageLoading(true);
    stationService
      .deleteStationById(initialValues.id)
      .then((response) => {
        setPageLoading(false);
        props.history.push({
          pathname: `/charge-points`,
          state: {
            alert: {
              type: "success",
              message: "Successfully deleted public charger",
            },
          },
        });
      })
      .catch((error) => {
        setAlert({
          type: "danger",
          message: (error.response && error.response.data.message) || error.message,
        });
        setPageLoading(false);
      });
  };
  useEffect(() => {
    if (props.match.params.id) {
      setPageLoading(true);
      stationService
        .getStationsById(props.match.params.id)
        .then((response) => {
          let values = response.data.station;
          values.station_id = values.station_id || "";
          values.street2 = values.street2 || "";
          values.company = values.company || "";
          values.site = values.site || "";
          values.price_per_kwh = parseFloat(values.price_per_kwh).toFixed(2);
          values.connection_fee = parseFloat(values.connection_fee).toFixed(2);
          values.connector_type = String(values.connector_type);
          values.type = String(values.type);
          companyService
            .getCompanies()
            .then((res) => {
              let company = res.data.data.find((item) => item.name === values.company);
              setSiteList(company ? company.sites : []);
            })
            .catch((error) => { });
          setInitialValues(values);
          setPageLoading(false);
        })
        .catch((error) => {
          setAlert({
            type: "danger",
            message: (error.response && error.response.data.message) || error.message,
          });
          setPageLoading(false);
        });
    }
    companyService
      .getCompanies()
      .then((response) => {
        setCompanyList(response.data.data);
      })
      .catch((error) => { });
  }, []);

  const changeCompany = (value) => {
    setInitialValues({ ...initialValues, ...{ site: "" } });
    if (value) {
      let company = companyList.find((item) => item.name === value);
      console.log(company)

      setSiteList(company ? company.sites : []);
    } else {
      setSiteList([]);
    }
  };
  console.log(siteList)
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(values, { setSubmitting, setErrors /* setValues and other goodies */ }) => {
        geocodeByAddress(values.street + " " + values.street2 + " " + values.city + " " + values.postcode)
          .then((results) => {
            return getLatLng(results[0]);
          })
          .then((latLng) => {
            handleSubmit({
              ...values,
              ...{ lat: latLng.lat, lon: latLng.lng },
            });
          })
          .catch((error) => {
            setErrors({
              street: "Please enter correct street",
              city: "Please enter correct city",
              postcode: "Please enter correct Postcode",
            });
          });
      }}
      render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <SiteWrapper loading={pageLoading}>
          <Page.Content>
            {alert.message && <Alert type={alert.type}>{alert.message}</Alert>}
            <Page.Header title='Charge Points' />
            <Grid.Row cards={true}>
              <Grid.Col width={12}>
                <Card className='charge-point-card'>
                  <Grid.Row>
                    <Grid.Col width={12} lg={8} className='m-auto'>
                      <Card.Header className='d-flex justify-content-between'>
                        <Svgicon name='cp' />
                        <Card.Title>{props.match.params.id ? "Edit " : "Add New "}Charge Point</Card.Title>
                        <div className='ml-auto lh-1'>
                          <Button
                            color='secondary'
                            onClick={() => {
                              props.match.params.id
                                ? props.history.push("/charge-points/" + props.match.params.id)
                                : props.history.push("/charge-points");
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Card.Header>
                    </Grid.Col>
                    <Grid.Col width={12} lg={12}>
                      <div className='border-bottom'></div>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col width={12} lg={8} className='m-auto'>
                      <div className='cp-new-body pt-40 pb-40'>
                        <form onSubmit={handleSubmit} autoComplete='off'>
                          <Grid.Row>
                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <Field
                                  name='station_id'
                                  className={errors.station_id && touched.station_id ? "form-control is-invalid state-invalid" : "form-control"}
                                  placeholder='Charge point ID'
                                />
                                {errors.station_id && touched.station_id && <div className='invalid-feedback'>{errors.station_id}</div>}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <Form.Select
                                  name='company'
                                  className={
                                    errors.company && touched.company
                                      ? "form-control form-select is-invalid state-invalid"
                                      : "form-control form-select"
                                  }
                                  value={values.company}
                                  // onChange={handleChange}
                                  onChange={(e) => {
                                    values.site = "";
                                    changeCompany(e.target.value);
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                >
                                  <option value=''>Select Company</option>
                                  {companyList.map((company, key) => (
                                    <option key={key} value={company.name}>
                                      {company.name}
                                    </option>
                                  ))}
                                </Form.Select>
                                {errors.company && touched.company && <div className='invalid-feedback'>{errors.company}</div>}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <Form.Select
                                  name='site'
                                  className={
                                    errors.site && touched.site ? "form-control form-select is-invalid state-invalid" : "form-control form-select"
                                  }
                                  value={values.site}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value=''>Select Site</option>
                                  {siteList.map((site, key) => (
                                    <option key={key} value={site.name}>
                                      {site.name}
                                    </option>
                                  ))}
                                </Form.Select>
                                {errors.site && touched.site && <div className='invalid-feedback'>{errors.site}</div>}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <Form.Select
                                  name='connector_type'
                                  className={
                                    errors.connector_type && touched.connector_type ? "form-control form-select is-invalid state-invalid" : "form-control form-select"
                                  }
                                  value={values.connector_type}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value=''>Connector Type</option>
                                  <option value='Type 1'>Type 1</option>
                                  <option value='Type 2'>Type 2</option>
                                </Form.Select>
                                {errors.connector_type && touched.connector_type && <div className='invalid-feedback'>{errors.connector_type}</div>}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <div className='input-group'>
                                  <span className='input-group-prepend'>
                                    <span className='input-group-text'>£</span>
                                  </span>
                                  <span
                                    className={
                                      errors.price_per_kwh && touched.price_per_kwh
                                        ? "form-control price_per_kwh is-invalid state-invalid"
                                        : "form-control price_per_kwh"
                                    }
                                  >
                                    {values.price_per_kwh && <span>£</span>}
                                    <Field
                                      name='price_per_kwh'
                                      className={values.price_per_kwh ? "" : "w-100"}
                                      placeholder='Price per kwh'
                                      style={{
                                        width: (values.price_per_kwh.toString().length + 1) * 8 + "px",
                                      }}
                                    />
                                    {values.price_per_kwh && <span style={{ color: '#979797' }}>per kwh</span>}
                                  </span>
                                  <div
                                    style={{
                                      width: values.price_per_kwh.length,
                                    }}
                                  ></div>
                                  {errors.price_per_kwh && touched.price_per_kwh && <div className='invalid-feedback'>{errors.price_per_kwh}</div>}
                                </div>
                              </div>
                            </Grid.Col>

                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <div className='input-group'>
                                  <span className='input-group-prepend'>
                                    <span className='input-group-text'>£</span>
                                  </span>
                                  <span
                                    className={
                                      errors.connection_fee && touched.connection_fee
                                        ? "form-control price_per_kwh is-invalid state-invalid"
                                        : "form-control price_per_kwh"
                                    }
                                  >
                                    {values.connection_fee && <span>£</span>}
                                    <Field
                                      name='connection_fee'
                                      placeholder='Connection Fee'
                                      className={values.connection_fee ? "" : "w-100"}
                                      style={{
                                        width: (values.connection_fee.toString().length + 1) * 8 + "px",
                                      }}
                                    />
                                    {values.connection_fee && <span style={{ color: '#979797' }}>Connection Fee</span>}
                                  </span>
                                  {errors.connection_fee && touched.connection_fee && <div className='invalid-feedback'>{errors.connection_fee}</div>}
                                </div>
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <Field
                                  name='street'
                                  className={errors.street && touched.street ? "form-control is-invalid state-invalid" : "form-control"}
                                  placeholder='Address Line 1'
                                />
                                {errors.street && touched.street && <div className='invalid-feedback'>{errors.street}</div>}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <Field
                                  name='street2'
                                  className={errors.street2 && touched.street2 ? "form-control is-invalid state-invalid" : "form-control"}
                                  placeholder='Address Line 2'
                                />
                                {errors.street2 && touched.street2 && <div className='invalid-feedback'>{errors.street2}</div>}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <Field
                                  name='city'
                                  className={errors.city && touched.city ? "form-control is-invalid state-invalid" : "form-control"}
                                  placeholder='Region'
                                />
                                {errors.city && touched.city && <div className='invalid-feedback'>{errors.city}</div>}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <Field
                                  name='postcode'
                                  className={errors.postcode && touched.postcode ? "form-control is-invalid state-invalid" : "form-control"}
                                  placeholder='Postcode'
                                />
                                {errors.postcode && touched.postcode && <div className='invalid-feedback'>{errors.postcode}</div>}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>

                              <div style={{ paddingBottom: 40 }}>
                                <CustomSelect
                                  options={[
                                    { label: 'Public', value: 'pub' }
                                    , { label: 'Private', value: 'prt' }
                                    // , { label: 'Home', value: 'h' }
                                  ]}
                                  placeholder='Access Type'
                                  onChange={(v) => values.type = v}
                                  value={values.type}
                                  error={errors}
                                />
                                {errors.type && (
                                  <div className="invalid-feedback">
                                    {errors.type}
                                  </div>
                                )}
                              </div>
                              <NavDropdown
                                alignRight
                                muted
                                title='What is this?'
                                className='what-this'
                              >
                                <div className="item">
                                  <h3>Public</h3>
                                  <p>
                                    All users registered on the Evaa network can view and use this charge point.
                                    Use this if you want to be able to generate revenue from your charge point.
                                  </p>
                                </div>
                                <NavDropdown.Divider />
                                <div className="item">
                                  <h3>Private</h3>
                                  <p>
                                    Only users added to your account can view and use this charge point.
                                    Use this if you want the charge point to be private to a select group of people.
                                  </p>
                                </div>
                              </NavDropdown>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <Form.Select
                                  name='connectors'
                                  className={"form-control form-select"}
                                  value={values.connectors}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value=''>Connectors</option>
                                  <option value='1'>1</option>
                                  <option value='2'>2</option>
                                  <option value='3'>3</option>
                                  <option value='4'>4</option>
                                  <option value='5'>5</option>
                                </Form.Select>
                                {/* {errors.connector_type && touched.connector_type && <div className="invalid-feedback">{errors.connector_type}</div>} */}
                              </div>
                            </Grid.Col>
                          </Grid.Row>


                          <button type='submit' className='btn btn-barlows' disabled={isLoading}>
                            {props.match.params.id ? "Save Changes" : "Add New Charge Point"}
                          </button>
                        </form>
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                  {props.match.params.id && (
                    <>
                      <Grid.Row>
                        <Grid.Col width={12} lg={12}>
                          <div className='border-bottom'></div>
                        </Grid.Col>
                        <Grid.Col width={12} lg={8} className='m-auto'>
                          <Card.Header className='d-flex justify-content-between'>
                            <Card.Title>Reset</Card.Title>
                          </Card.Header>
                        </Grid.Col>
                        <Grid.Col width={12} lg={12}>
                          <div className='border-bottom'></div>
                        </Grid.Col>
                        <Grid.Col width={12} lg={8} className='m-auto'>
                          <Card.Body className='pt-40 pb-40'>
                            <div className='pb-40' style={{ color: '#74797e' }}>This sends a reset command to the charge point.</div>
                            <Grid.Row>
                              <form className='w-100'>
                                <Grid.Col width={12} lg={6}>
                                  <div className='pb-40'>
                                    <Form.Select
                                      name='reset_type'
                                      className={errorReset ? "form-control form-select is-invalid state-invalid" : "form-control form-select"}
                                      value={resetType}
                                      onChange={(e) => {
                                        setResetType(e.target.value);
                                        if (e.target.value) {
                                          setErrorResetType(false);
                                        } else {
                                          setErrorResetType(true);
                                        }
                                      }}
                                    >
                                      <option value=''>Reset Type</option>
                                      <option value='Hard'>Hard</option>
                                      <option value='Soft'>Soft</option>
                                    </Form.Select>
                                    {errorReset && <div className='invalid-feedback'>Please select Reset Type</div>}
                                  </div>
                                </Grid.Col>
                              </form>
                            </Grid.Row>
                            <div className=''>
                              {resetAlert.message && <Alert type={resetAlert.type}>{resetAlert.message}</Alert>}
                              <Button color='secondary' onClick={handleReset}>
                                Reset
                              </Button>
                            </div>
                          </Card.Body>
                        </Grid.Col>
                      </Grid.Row>
                      {/* <Grid.Row>
                        <Grid.Col width={12} lg={12}>
                          <div className='border-bottom'></div>
                        </Grid.Col>
                        <Grid.Col width={12} lg={8} className='m-auto'>
                          <Card.Header className='d-flex justify-content-between'>
                            <Card.Title>Update Firmware</Card.Title>
                          </Card.Header>
                        </Grid.Col>
                        <Grid.Col width={12} lg={12}>
                          <div className='border-bottom'></div>
                        </Grid.Col>
                        <Grid.Col width={12} lg={8} className='m-auto'>
                          <Card.Body className='pt-40 pb-40'>
                            <div className='mb-4' style={{ color: '#74797e' }}>Use this if you need to update the firmware of your charge point.</div>
                            <Grid.Row>
                              <form className='w-100'>
                                <Grid.Col width={12} lg={6}>
                                  <div className='form-group'>
                                    <Field
                                      name='location'
                                      className={errorLocation ? "form-control is-invalid state-invalid" : "form-control"}
                                      placeholder='Firmware Location'
                                      value={firmLocation}
                                      onChange={(e) => {
                                        setFirmLocation(e.target.value);
                                        if (e.target.value) {
                                          setErrorLocation(false);
                                        } else {
                                          setErrorLocation(true);
                                        }
                                      }}
                                    />
                                    {errorLocation && <div className='invalid-feedback'>Please add firmware location</div>}
                                  </div>
                                </Grid.Col>
                              </form>
                            </Grid.Row>

                            <div className='mb-4'>
                              <Button color='upload'>Upload File</Button>
                            </div>
                            <div className=''>
                              {firmwareAlert.message && <Alert type={firmwareAlert.type}>{firmwareAlert.message}</Alert>}
                              <Button color='secondary' onClick={handleUpdateFirmware}>
                                Update Firmware
                              </Button>
                            </div>
                          </Card.Body>
                        </Grid.Col>
                      </Grid.Row> */}
                      <Grid.Row className='mt-3'>
                        <Grid.Col width={12} lg={8} className='m-auto '>
                          <Card.Body>
                            <div className='d-flex user-page'>
                              <Button outline color='danger' onClick={handleShow} disabled={isLoading}>
                                Delete Charge Point
                              </Button>
                              <span
                                style={{
                                  color: "#FF4747",
                                  fontSize: 11,
                                  marginLeft: 20,
                                }}
                              >
                                Note: The charge point will no longer be registered.
                              </span>
                            </div>
                          </Card.Body>
                          <Modal show={show} centered={true} onHide={handleClose} dialogClassName='delete-user'>
                            <Modal.Body>
                              <div style={{ textAlign: "right", cursor: "pointer" }}>
                                <svg width='21px' height='20px' viewBox='0 0 32 32' onClick={handleClose}>
                                  <g stroke='#E5E9EF' stroke-width='1' fill='none' fill-rule='evenodd'>
                                    <g transform='translate(-419.000000, -240.000000)' fill='#E5E9EF'>
                                      <g id='icons' transform='translate(56.000000, 160.000000)'>
                                        <polygon points='375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446'></polygon>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className='d-flex justify-content-center align-items-center notification-content'>
                                <svg width='50px' height='50px' viewBox='0 0 50 50' className='delete-svg'>
                                  <title>F43EE6C7-25E5-4915-8583-45C7C5200063</title>
                                  <g id='Desktop-App---BRANDED-V2' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                                    <g id='Settings---Delete-User-Modal' transform='translate(-695.000000, -376.000000)' fill='#FF4747'>
                                      <g id='Group-8' transform='translate(530.000000, 336.000000)'>
                                        <g id='Group-6' transform='translate(165.000000, 40.000000)'>
                                          <rect id='Rectangle' fill-opacity='0.1' x='0' y='0' width='50' height='50' rx='5'></rect>
                                          <g id='delete' transform='translate(15.000000, 15.000000)'>
                                            <path
                                              d='M5,2 C5,0.89543 5.89543,0 7,0 L13,0 C14.1046,0 15,0.89543 15,2 L15,4 L16.9897,4 C16.9959,3.99994 17.0021,3.99994 17.0083,4 L19,4 C19.5523,4 20,4.44772 20,5 C20,5.55228 19.5523,6 19,6 L17.9311,6 L17.0638,18.1425 C16.989,19.1891 16.1182,20 15.0689,20 L4.93112,20 C3.88184,20 3.01096,19.1891 2.9362,18.1425 L2.06888,6 L1,6 C0.44772,6 0,5.55228 0,5 C0,4.44772 0.44772,4 1,4 L2.99174,4 C2.99795,3.99994 3.00414,3.99994 3.01032,4 L5,4 L5,2 Z M7,4 L13,4 L13,2 L7,2 L7,4 Z M4.07398,6 L4.93112,18 L15.0689,18 L15.926,6 L4.07398,6 Z M8,8 C8.5523,8 9,8.4477 9,9 L9,15 C9,15.5523 8.5523,16 8,16 C7.44772,16 7,15.5523 7,15 L7,9 C7,8.4477 7.44772,8 8,8 Z M12,8 C12.5523,8 13,8.4477 13,9 L13,15 C13,15.5523 12.5523,16 12,16 C11.4477,16 11,15.5523 11,15 L11,9 C11,8.4477 11.4477,8 12,8 Z'
                                              id='Shape'
                                            ></path>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                                <div className='content-title'>Delete Charge Point?</div>
                                <div className='content-note'>Are you sure you want to delete this Charge Point? </div>
                                <div className='d-flex user-page' style={{ marginTop: 20 }}>
                                  <Button outline color='danger' onClick={deleteChargePoint}>
                                    Yes, Delete Charge Point
                                  </Button>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </Grid.Col>
                      </Grid.Row>
                    </>
                  )}
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Page.Content>
        </SiteWrapper>
      )}
    />
  );
}

function ChargePointsPage(props) {
  const clientRef = useRef(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [alert, setAlert] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [stations, setStationsData] = useState([]);
  const [activeStations, setActiveStations] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [siteVal, setSiteValue] = useState("");
  const { user } = useSelector(state => state.auth);
  const [filterParams, setParams] = useState({
    "filter[company]": "",
    "filter[site]": "",
    page: 1,
  });
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangeSiteFilter = (values) => {
    setSiteValue(values.currentTarget.value);
    setParams({
      ...filterParams,
      ...{ "filter[site]": values.currentTarget.value },
    });
  };
  const handleChangeCompanyFilter = (values) => {
    let value = values.currentTarget.value;
    setSiteValue("");
    if (value) {
      let company = companyList.find((item) => item.name === value);
      setSiteList(company ? company.sites : []);
    } else {
      setSiteList([]);
    }
    setParams({
      ...filterParams,
      ...{ "filter[company]": values.currentTarget.value, "filter[site]": "" },
    });
  };

  React.useEffect(() => {
    setPageLoading(true);
    stationService
      .getStationsByUser(filterParams)
      .then((response) => {
        setTotalPage(Math.ceil(response.data.stations.total / 10));
        setCurrentPage(response.data.stations.current_page);
        setStationsData(response.data.stations.data);
        setPageLoading(false);
        if (clientRef.current) {
          clientRef.current.send("ping");
        }
      })
      .catch((error) => {
        setPageLoading(false);
      });
  }, [filterParams]);
  useEffect(() => {
    companyService
      .getCompanies()
      .then((response) => {
        setCompanyList(response.data.data);
      })
      .catch((error) => { });
    billService.checkSubscription()
      .then((res) => {
        if (res.data.sub === 'canceled' || res.data.sub === 'failed') {
          setShowAlert(res.data.sub);
        }
      })
      .catch((error) => {
      });
  }, []);
  useEffect(() => {
    updateNotification();
  }, [activeStations]);

  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }
    if (!clientRef.current) {
      const client = new WebSocket(SOCKET_URL);
      clientRef.current = client;
      window.client = client;
      client.onerror = (e) => console.error(e);
      client.onopen = () => {
        // client.send("ping");
      };
      client.onclose = () => {
        if (clientRef.current) {
          setActiveStations([]);
        } else {
          return;
        }
        if (waitingToReconnect) {
          return;
        }
        setWaitingToReconnect(true);
        setTimeout(() => setWaitingToReconnect(null), 5000);
      };
      client.onmessage = (message) => {
        if (JSON.parse(message.data).stations) {
          setActiveStations(JSON.parse(message.data).stations);
        }
      };
      return () => {
        clientRef.current = null;
        client.close();
      };
    }
  }, [waitingToReconnect]);

  function updateNotification() {
    let temp = stations.map((item) => {
      item.active = false;
      const station = activeStations.filter((station) => {
        return item.id == station.id;
      });
      if (station.length > 0) {
        item.active = true;
      }
      return item;
    });
    let errorCount = temp.filter((item) => {
      return !item.active;
    });
    setAlert(errorCount);
    setStationsData(temp);
  }

  return (
    <SiteWrapper loading={pageLoading}>
      <Page.Content>
        {
          showAlert ?
            showAlert === 'canceled' ?
              <Alert type='danger-chargebox' icon='x-circle'>
                Your subscription has been cancelled. Please go the billing screen and select a package to reactivate your subscription.
              </Alert> :
              <Alert type='danger-chargebox' icon='x-circle'>
                <span>
                  Your subscription is currently inactive. You will no longer be able to access the software from the end of the current billing cycle. <a href={`${FRONTENDURL}/billing/plan`}>Click here</a> to update your subscription and continue using Evaa.
                </span>
              </Alert> : null
        }
        {alert.length > 0 ? (
          <Alert type='danger-chargebox' icon='x-circle'>
            Charge Point is down!
          </Alert>
        ) : null
        }

        <div className='page-header'>
          <h1 className='page-title d-flex w-100 justify-content-between mb-2'>
            Charge Points
            <Button
              className='d-sm-block'
              color='barlows'
              onClick={() => {
                props.history.push("charge-points/new");
              }}
            >
              Add New <i className='fe fe-plus'></i>
            </Button>
          </h1>
          <div className='page-options d-flex'>
            {/* <Form.Select className='form-select w-auto mr-2' onChange={handleChangeCompanyFilter}>
              <option value=''>Filter by Company</option>
              {companyList.map((item, key) => (
                <option key={key} value={item.name}>
                  {item.name}
                </option>
              ))}
              <option value="Company 1">Company 1</option>
              <option value="Company 2">Company 2</option>
              <option value="Company 3">Company 3</option>
              <option value="Company 4">Company 4</option>
            </Form.Select> */}
            {/* <Form.Select className='form-select w-auto mr-2' onChange={handleChangeSiteFilter} value={siteVal}>
              <option value=''>Filter by Site</option>
              {siteList.map((item, key) => (
                <option key={key} value={item.name}>
                  {item.name}
                </option>
              ))}
              <option value="Site 1">Site 1</option>
              <option value="Site 2">Site 2</option>
              <option value="Site 3">Site 3</option>
              <option value="Site 4">Site 4</option>
            </Form.Select> */}
            <Button
              className='d-sm-none'
              color='barlows'
              onClick={() => {
                props.history.push("charge-points/new");
              }}
            >
              Add New <i className='fe fe-plus'></i>
            </Button>
          </div>
        </div>
        <Grid.Row cards={true}>
          <UptimeCard />
          <Grid.Col width={12} lg={6}>
            <Card className='h-100'>
              <Card.Body className='p-0'>
                <div
                  style={{
                    height: "auto",
                    minHeight: 300,
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div className='map-title'>
                    <Svgicon name='pin' />
                    <span>Locations</span>
                  </div>
                  <GoogleMap stations={stations} />
                </div>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Card className={stations.length === 0 ? 'user-fixed-card' : ''}>
              <Card.Header>
                <Svgicon name='cp' />
                <Card.Title>Charge Point Summary</Card.Title>
              </Card.Header>
              {stations.length > 0 ?
                <Table cards={true} striped={true} responsive={true} className='table card-table table-vcenter datatable'>
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>ID</Table.ColHeader>
                      <Table.ColHeader>LOCATION</Table.ColHeader>
                      <Table.ColHeader>UPTIME</Table.ColHeader>
                      <Table.ColHeader>DOWNTIME</Table.ColHeader>
                      {user && user.type === 'Super' && <Table.ColHeader>Accound id</Table.ColHeader>}
                      <Table.ColHeader />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {stations.map((station, key) => (
                      <Table.Row key={key} className={station.active ? "" : "down"}>
                        <Table.Col>{station.id}</Table.Col>
                        <Table.Col className='text-nowrap mobile-locations'>{station.street}</Table.Col>
                        <Table.Col>{station.total_count ? Math.floor((station.up_count / station.total_count) * 100).toFixed(0) : 0}%</Table.Col>
                        <Table.Col>
                          {station.total_count ? (100 - Math.floor((station.up_count / station.total_count) * 100)).toFixed(0) : 100}%
                        </Table.Col>
                        {user && user.type === 'Super' && <Table.Col>{station.account_id}</Table.Col>}
                        <Table.Col>
                          <Button
                            color='secondary'
                            onClick={() => {
                              props.history.push("/charge-points/" + station.id);
                            }}
                          >
                            View
                          </Button>
                        </Table.Col>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table> :
                <Card.Body className="text-center justify-content-center">
                  <div className="h4" style={{ marginBottom: 20 }}>No Charge Points</div>
                  <div className="detail mb-5">You haven’t created any charge points yet.</div>
                </Card.Body>
              }
              {stations.length > 0 &&
                <div className='pagination-barlows'>
                  <Button
                    color='secondary'
                    onClick={() => {
                      setParams({
                        ...filterParams,
                        ...{ page: currentPage - 1 },
                      });
                    }}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </Button>

                  <span>
                    {currentPage} of {totalPage}
                  </span>
                  <Button
                    color='secondary'
                    onClick={() => {
                      setParams({
                        ...filterParams,
                        ...{ page: currentPage + 1 },
                      });
                    }}
                    disabled={currentPage === totalPage}
                  >
                    Next
                  </Button>
                </div>}
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default ChargePointsPage;
export { AddNewChargePoint, SingleChargePoint };
