import React, { useEffect, useState } from "react";
import SiteWrapper from "../../SiteWrapper";
import { Page, Grid, Card, Table, Alert, Progress, Button } from "tabler-react";
import { NavDropdown } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import Svgicon from "../../components/SvgIcon";
import * as StripeTransactionService from "../../store/services/stripeTransactionsService";
import * as billService from "../../store/services/billServices";
import Moment from "react-moment";
import StripePaymentNav from "./StripePaymentNav";
import { useSelector, useDispatch } from "react-redux";
import { setDetailsSubmitted, authLogin } from "../../store/actions/authActions";
import PaymentsPage from "./Payments";
import { FRONTENDURL } from "../../store/services/type";
const StripePaymentsIndex = (props) => {
  const { detailsSubmitted, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [payoutAlert, setPayoutAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const handleGetOnboarding = () => {
    setLoading(true)
    StripeTransactionService.getOnboarding()
      .then((response) => {
        dispatch(authLogin(response.data.user));
        localStorage.setItem("user", JSON.stringify(response.data.user));
        setLoading(false);
        window.location = response.data.account.url;
      })
      .catch((error) => {
        setLoading(false);
        setAlert({
          type: "danger",
          message: (error.response && error.response.data.message) || error.message,
        });
      });
  };
  useEffect(() => {
    if (!detailsSubmitted) {
      setLoading(true);
      StripeTransactionService.checkStripeAccount()
        .then((response) => {
          dispatch(setDetailsSubmitted({ detailsSubmitted: response.data.details_submitted }));
          // if (user.subscription_id && response.data?.account?.id) {
          //   dispatch(setDetailsSubmitted({ detailsSubmitted: true }));
          // }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // setAlert({
          //   type: "danger",
          //   message: (error.response && error.response.data.message) || error.message,
          // });
        });
    }
    billService.checkSubscription()
      .then((res) => {
        if (res.data.sub === 'canceled' || res.data.sub === 'failed') {
          setShowAlert(res.data.sub);
          // if (res.data.sub === "" && !user.subscription_id) {
          //   setShowAlert('canceled')
          // }
        }

        if (res.data.payout === 'failed') {
          setPayoutAlert(true);
        }
      })
      .catch((error) => {
      });
  }, []);
  return (
    <SiteWrapper loading={loading}>
      <StripePaymentNav />
      <Page.Content >
        {showAlert ? (
          showAlert === 'canceled' ?
            <Alert type='danger-chargebox' icon='x-circle'>
              Your subscription has been cancelled. Please go the billing screen and select a package to reactivate your subscription.
            </Alert> :
            <Alert type='danger-chargebox' icon='x-circle'>
              <span>
                Your subscription is currently inactive. You will no longer be able to access the software from the end of the current billing cycle. <a href={`${FRONTENDURL}/billing/plan`}>Click here</a> to update your subscription and continue using Evaa.
              </span>
            </Alert>
        ) : null}
        {payoutAlert &&
          <Alert type='danger-chargebox' icon='x-circle' onClick={() => setPayoutAlert(false)}>
            A payout to your bank has failed. Please update your account details on the payment settings page. If you think this is an error then please contact Evaa support.
          </Alert>
        }
        <div className="page-header">
          <h1 className="page-title">{detailsSubmitted ? 'Transactions' : 'Payments'}</h1>
        </div>
        {alert.message && <Alert type={alert.type}>{alert.message}</Alert>}
        {detailsSubmitted ? (
          <PaymentsPage history={props.history} loading={loading} setLoading={setLoading} />
        ) : (
          <Grid.Row>
            <Grid.Col>
              <Card>
                <Card.Header>
                  <Svgicon name='payments' />
                  <Card.Title className='d-flex'>Payments</Card.Title>
                </Card.Header>
                <Card.Body className='get-payments'>
                  <div className='title' style={{ color: '#000' }}>Setup Your Account</div>
                  <div className='details' style={{ color: '#74797E' }}>
                    You are not setup to receive payments yet. We need some details from you to setup your payment processing account.
                  </div>
                  <Button color='barlows' onClick={handleGetOnboarding}>
                    Get started
                  </Button>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        )}
      </Page.Content>
    </SiteWrapper>
  );
};

export default StripePaymentsIndex;
