let api_url = "http://localhost:8000/api/";
let remote_url = "http://localhost:7000/api/occp16/ws/";
let socket_url = "ws://localhost:7000/status";

// if (process.env.NODE_ENV === "development") {
//   api_url = "http://localhost:8000/api/";
// }

// if (process.env.NODE_ENV === "production") {
api_url = "https://staging.cloud.evaa.app/rest/api/";
remote_url = "https://staging.socket.evaa.app/api/occp16/ws/";
socket_url = "wss://staging.socket.evaa.app/status";

// }
let url = "https://staging.cloud.evaa.app/rest";
let url1 = "https://staging.cloud.evaa.app";
export const BACKENDURL = url;
export const FRONTENDURL = url1;
export const BASE_URL = api_url;
export const REMOTE_URL = remote_url;
export const SOCKET_URL = socket_url;
