// @flow

import * as React from "react";
import { Page, Grid, Card, Table, Alert, Form, Button } from "tabler-react";
import ApexChart from "../components/ApexChart";
import * as TransactionServices from "../store/services/transactionsService";
import * as billService from "../store/services/billServices";
import SiteWrapper from "../SiteWrapper";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";
import "../components/DateRange/Calendar.css";
import "../components/DateRange/DateRangePicker.css";
import Loading from "react-loading";
import moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import * as companyService from "../store/services/companyServices";
import {FRONTENDURL} from '../store/services/type'

function ReportsPage(props) {
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [transactions, setTransactions] = React.useState([]);
  const [allTransactions, setAllTransactions] = React.useState([]);
  const [daterange, setDateRange] = React.useState([new Date(), new Date()]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [filterParams, setParams] = React.useState({
    page: 1,
  });
  const [energyPeriod, setEnergyPeriod] = React.useState("Last 7 days");
  const [chargingPeriod, setChargingPeriod] = React.useState("Last 7 days");
  const [revenuePeriod, setRevenuePeriod] = React.useState("Last 7 days");

  const [energeyData, setEnergyData] = React.useState({
    categories: [],
    series: [],
    percent: 0,
    value: 0,
    type: 1,
  });
  const [chargeTime, setChargeTime] = React.useState({
    categories: [],
    series: [],
    percent: 0,
    value: 0,
    type: 2,
  });
  const [revenueData, setRevenueData] = React.useState({
    categories: [],
    series: [],
    percent: 0,
    value: 0,
    type: 3,
  });
  const [companyList, setCompanyList] = React.useState([]);
  const [siteList, setSiteList] = React.useState([]);
  const [siteVal, setSiteValue] = React.useState("");

  React.useEffect(() => {
    TransactionServices.getAllTransactions()
      .then((response) => {
        let values = response.data.transactions;
        setAllTransactions(values);
      })
      .catch((error) => { });

    TransactionServices.getTransations()
      .then((response) => {
        let values = response.data.transactions;
        setTransactions(values.data);
        setCurrentPage(values.current_page);
        setTotalPage(values.last_page);
        setLoading(false);
      })
      .catch((error) => {
        console.log("+", error);
        setLoading(false);
      });
    getData(0, 7);
    companyService
      .getCompanies()
      .then((response) => {
        setCompanyList(response.data.data);
      })
      .catch((error) => { });
    billService.checkSubscription()
      .then((res) => {
        if (res.data.sub === 'canceled' || res.data.sub === 'failed') {
          setShowAlert(res.data.sub);
        }
      })
      .catch((error) => {
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    TransactionServices.getTransations(filterParams)
      .then((response) => {
        let values = response.data.transactions;
        setTransactions(values.data);
        setTotalPage(Math.ceil(values.data.length / 10));
        setTotalPage(values.last_page);
        setCurrentPage(values.current_page);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [filterParams]);

  const convertHours = (value) => {
    var n = new Date(0, 0);
    n.setSeconds(+value);
    return n.toTimeString().slice(0, 5);
  };

  const handleChangeSiteFilter = (values) => {
    setSiteValue(values.currentTarget.value);
    setParams({
      ...filterParams,
      ...{
        site: values.currentTarget.value ? values.currentTarget.value : null,
      },
    });
  };
  const handleChangeCompanyFilter = (values) => {
    let value = values.currentTarget.value;
    setSiteValue("");
    if (value) {
      let company = companyList.find((item) => item.name === value);
      setSiteList(company ? company.sites : []);
    } else {
      setSiteList([]);
    }
    setParams({
      ...filterParams,
      ...{
        company: values.currentTarget.value ? values.currentTarget.value : null,
        site: null,
      },
    });
  };

  const getData = (type, period) => {
    setLoading(true);
    if (type === 0) {
      TransactionServices.getEnergy({ period: "7" })
        .then((response) => {
          const { chart_data, percent, period_energy } = response.data;
          let categories = [],
            series = [];
          chart_data.forEach((d) => {
            categories.push(d.date);
            series.push(d.date_energy);
          });
          let params = {
            categories,
            series,
            percent,
            value: period_energy.toFixed(2),
            type: 1,
          };
          setEnergyData(params);
        })
        .catch((err) => setLoading(false));

      TransactionServices.getChargeTime({ period })
        .then((response) => {
          const { chart_data, percent, period_charge_time } = response.data;
          let categories = [],
            series = [];
          chart_data.forEach((d) => {
            categories.push(d.date);
            series.push(d.date_charge_time);
          });
          let params = {
            categories,
            series,
            percent,
            value: convertHours(period_charge_time),
            type: 2,
          };
          setChargeTime(params);
        })
        .catch((err) => setLoading(false));
      TransactionServices.getRevenue({ period })
        .then((response) => {
          const { chart_data, percent, period_revenue } = response.data;
          let categories = [],
            series = [];
          chart_data.forEach((d) => {
            categories.push(d.date);
            series.push(d.date_charge_cost);
          });
          let params = {
            categories,
            series,
            percent,
            value: "£" + period_revenue / 100,
            type: 3,
          };
          setRevenueData(params);
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    } else if (type === 1) {
      TransactionServices.getEnergy({ period })
        .then((response) => {
          const { chart_data, percent, period_energy } = response.data;
          let categories = [],
            series = [];
          chart_data.forEach((d) => {
            categories.push(d.date);
            series.push(d.date_energy);
          });
          let params = {
            categories,
            series,
            percent,
            value: period_energy.toFixed(2),
            type: 1,
          };
          setEnergyData(params);
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    } else if (type === 2) {
      TransactionServices.getChargeTime({ period })
        .then((response) => {
          const { chart_data, percent, period_charge_time } = response.data;
          let categories = [],
            series = [];
          chart_data.forEach((d) => {
            categories.push(d.date);
            series.push(d.date_charge_time);
          });
          let params = {
            categories,
            series,
            percent,
            value: convertHours(period_charge_time),
            type: 2,
          };
          setChargeTime(params);
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    } else {
      TransactionServices.getRevenue({ period })
        .then((response) => {
          const { chart_data, percent, period_revenue } = response.data;
          let categories = [],
            series = [];
          chart_data.forEach((d) => {
            categories.push(d.date);
            series.push(d.date_charge_cost);
          });
          let params = {
            categories,
            series,
            percent,
            value: "£" + period_revenue / 100,
            type: 3,
          };

          setRevenueData(params);
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    }
  };
  const changePeriod = (p, t) => {
    if (t === 1) {
      setEnergyPeriod(p);
      if (p === "Last 7 days") {
        getData(1, 7);
      } else if (p === "Last 30 days") {
        getData(1, 30);
      } else {
        getData(1, 90);
      }
    } else if (t === 2) {
      setChargingPeriod(p);
      if (p === "Last 7 days") {
        getData(2, 7);
      } else if (p === "Last 30 days") {
        getData(2, 30);
      } else {
        getData(2, 90);
      }
    } else {
      setRevenuePeriod(p);
      if (p === "Last 7 days") {
        getData(3, 7);
      } else if (p === "Last 30 days") {
        getData(3, 30);
      } else {
        getData(3, 90);
      }
    }
  };
  const changeDateRange = (range) => {
    console.log(range);
    let datarange = [
      moment(range[0]).format("YYYY-MM-DD"),
      moment(range[1]).format("YYYY-MM-DD"),
    ];
    setDateRange(range);
    setParams({
      ...filterParams,
      ...{ daterange: JSON.stringify(datarange) },
    });
  };
  const getDateFormat = (range) => {
    return (
      moment(range[0]).format("DD MMM YYYY") +
      " - " +
      moment(range[1]).format("DD MMM YYYY")
    );
  };
  const exportToCSV = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let array = [];
    transactions.forEach((transaction) => {
      let obj = {};
      obj.id = transaction.id;
      obj.name = transaction.user.name;
      obj.date = moment(transaction.charge_date).format("DD MMM YYYY");
      obj["charge time"] = convertHours(transaction.charging_time);
      obj["energy used"] = transaction.total_kwh.toFixed(2) + "kwh";
      obj.price = "£" + (transaction.charge_cost / 100).toFixed(2);
      obj["charge time"] = convertHours(transaction.charging_time);
      obj["energy used"] = transaction.total_kwh.toFixed(2) + "kwh";
      obj.price = "£" + (transaction.charge_cost / 100).toFixed(2);
      array.push(obj);
    });
    const ws = XLSX.utils.json_to_sheet(array);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: false,
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      moment().format("YYYY_MM_DD_hh:mm:ss") + fileExtension
    );
  };
  return (
    <SiteWrapper loading={loading}>
      <Page.Content>
        {
          showAlert ?
            showAlert === 'canceled' ?
              <Alert type='danger-chargebox' icon='x-circle'>
                Your subscription has been cancelled. Please go the billing screen and select a package to reactivate your subscription.
              </Alert> :
              <Alert type='danger-chargebox' icon='x-circle'>
                <span>
                  Your subscription is currently inactive. You will no longer be able to access the software from the end of the current billing cycle. <a href={`${FRONTENDURL}/billing/plan`}>Click here</a> to update your subscription and continue using Evaa.
                </span>
              </Alert> : null
        }
        <div className="page-header">
          <h1 className="page-title d-flex w-100 justify-content-between mb-2 reports-title">
            Reports
          </h1>
          <div className="page-options d-flex reports">
            <div className="d-flex">
              <Form.Select
                className="form-select w-auto mr-2"
                onChange={handleChangeCompanyFilter}
              >
                <option value="">Filter by Company</option>
                {companyList.map((item, key) => (
                  <option key={key} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                className="form-select w-auto mr-2"
                onChange={handleChangeSiteFilter}
                value={siteVal}
              >
                <option value="">Filter by Site</option>
                {siteList.map((item, key) => (
                  <option key={key} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="d-flex">
              <div className="custom-datepicker">
                <DateRangePicker
                  onChange={changeDateRange}
                  value={daterange}
                  calendarIcon={null}
                  clearIcon={null}
                  format="dd MMM y"
                  isOpen={datePickerOpen}
                  onCalendarClose={() => {
                    setDatePickerOpen(false);
                  }}
                />
                <input
                  className="form-control date-input"
                  readOnly
                  value={getDateFormat(daterange)}
                  onClick={() => {
                    setDatePickerOpen(true);
                  }}
                />
              </div>

              <Button color="barlows" onClick={exportToCSV}>
                Export <i className="fe fe-upload mr-2"></i>
              </Button>
            </div>
          </div>
        </div>
        <Grid.Row cards={true}>
          <ApexChart
            title="ENERGY CONSUMPTION"
            unit="640kwh"
            data={energeyData}
            period={energyPeriod}
            changePeriod={changePeriod}
          />
          <ApexChart
            title="CHANGING TIME TOTAL"
            unit="4:23hrs"
            period={chargingPeriod}
            changePeriod={changePeriod}
            data={chargeTime}
          />
          <ApexChart
            title="REVENUE"
            unit="£4300"
            data={revenueData}
            period={revenuePeriod}
            changePeriod={changePeriod}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Card>
              <Card.Header>
                <Card.Title>Transactions</Card.Title>
              </Card.Header>
              {transactions.length > 0 ?
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table card-table table-vcenter datatable"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>ID</Table.ColHeader>
                      <Table.ColHeader>NAME</Table.ColHeader>
                      <Table.ColHeader>DATE</Table.ColHeader>
                      <Table.ColHeader>CHARGE TIME</Table.ColHeader>
                      <Table.ColHeader>ENERGY USED</Table.ColHeader>
                      <Table.ColHeader>PRICE</Table.ColHeader>
                      <Table.ColHeader>TRANSACTION ID</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {transactions.sort((a, b) => {
                      if (a.id > b.id) return -1
                      if (a.id < b.id) return 1
                      return 0
                    }).map((transaction, key) => (
                      <Table.Row key={key}>
                        <Table.Col>{transaction.id}</Table.Col>
                        <Table.Col className="text-nowrap mobile-locations">
                          {transaction.user.name}
                        </Table.Col>
                        <Table.Col>
                          {moment(transaction.charge_date).format("HH:mm DD MMM YYYY")}
                        </Table.Col>
                        <Table.Col>
                          {convertHours(transaction.charging_time)}
                        </Table.Col>
                        <Table.Col>
                          {transaction.total_kwh.toFixed(2)}kwh
                        </Table.Col>
                        <Table.Col>
                          £{(transaction.charge_cost / 100).toFixed(2)}
                        </Table.Col>
                        <Table.Col>
                          {transaction.charge_id}
                        </Table.Col>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table> :
                <Card.Body className='align-items-center justify-content-center'>
                  <h4 className="h4 mt-4 text-center" style={{ color: '#000', marginBottom: 20 }}>
                    No Transactions
                  </h4>
                  <div className="detail mb-5">You haven’t made any transactions yet.</div>
                </Card.Body>}
              {transactions.length > 0 &&
                <div className="pagination-barlows">
                  <Button
                    color="secondary"
                    onClick={() => {
                      setParams({
                        ...filterParams,
                        ...{ page: currentPage - 1 },
                      });
                    }}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </Button>

                  <span>
                    {currentPage} of {totalPage}
                  </span>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setParams({
                        ...filterParams,
                        ...{ page: currentPage + 1 },
                      });
                    }}
                    disabled={currentPage === totalPage}
                  >
                    Next
                  </Button>
                </div>}
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default ReportsPage;
