// @flow

import * as React from "react";
import { useState, useEffect, useFocus, useRef } from "react";
import { Page, Grid, Card, Table, Alert, Form, Button } from "tabler-react";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import SiteWrapper from "../SiteWrapper";
import * as userService from "../store/services/userService";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { Modal } from "react-bootstrap";
import Svgicon from "../components/SvgIcon";
import CustomSelect from "../components/select";
const formSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  type: Yup.string().required("Type is required"),
  // account_id: Yup.string().required("Account ID is required"),
});
const formSchema1 = Yup.object().shape({
  station_id: Yup.string().required("Charge point ID is required"),
  type: Yup.string().required("Charge point ID is required"),
  price_per_kwh: Yup.number().required("Price per kwh is required"),
  connection_fee: Yup.number().required("Connection Fee is required"),
});

function UsersPage(props) {
  const [loading, setLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [users, setUsers] = React.useState([]);
  const [filterParams, setParams] = React.useState({
    page: 1,
    "filter[type]": "",
  });
  const handleChangeRoleFilter = (values) => {
    setParams({
      ...filterParams,
      ...{ "filter[type]": values },
    });
  };
  React.useEffect(() => {
    setLoading(true);
    userService
      .getUsers(filterParams)
      .then((response) => {
        setUsers(response.data.users.data);
        setTotalPage(Math.ceil(response.data.users.total / 10));
        setCurrentPage(response.data.users.current_page);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [filterParams]);

  return (
    <SiteWrapper loading={loading}>
      <Page.Content>
        <div className="page-header">
          <h1 className="page-title">Users</h1>
          <div className="page-options d-flex">
            <Button
              color="barlows"
              onClick={() => {
                props.history.push("users/new");
              }}
            >
              Add New <i className="fe fe-plus"></i>
            </Button>
          </div>
        </div>
        <Grid.Row>
          <Grid.Col>
            <Card className={users.length === 0 ? "charge-point-card" : ''}>
              <Card.Header>
                <Svgicon name='user' />
                <Card.Title>Users</Card.Title>

                <Card.Options>
                  <div style={{ width: 118 }}>
                    <CustomSelect
                      options={[
                        { label: 'Admins', value: 'Admin' }
                        , { label: 'Managers', value: 'Manager' }
                        , { label: 'Customers', value: 'Customer' }
                      ]}
                      placeholder='Filter by Role'
                      onChange={handleChangeRoleFilter}
                      value={filterParams["filter[type]"]}
                      filter
                    />
                  </div>
                </Card.Options>
              </Card.Header>
              {users.length > 0 ?
                <>
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table card-table table-vcenter datatable"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader>NAME</Table.ColHeader>
                        <Table.ColHeader>ROLE</Table.ColHeader>
                        <Table.ColHeader>Account ID</Table.ColHeader>
                        <Table.ColHeader>Group ID</Table.ColHeader>
                        <Table.ColHeader />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {users.map((user, key) => (
                        <Table.Row key={key}>
                          <Table.Col className="fit-content">{user.name}</Table.Col>
                          <Table.Col>{user.type}</Table.Col>
                          <Table.Col>{user.type==='Admin'?user.customer_id:''}</Table.Col>
                          <Table.Col>{user.account_id}</Table.Col>
                          <Table.Col>
                            <Button
                              color="secondary"
                              onClick={() => {
                                props.history.push("/users/" + user.id);
                              }}
                            >
                              Edit
                            </Button>
                          </Table.Col>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <div className="pagination-barlows">
                    <Button
                      color="secondary"
                      onClick={() => {
                        setParams({
                          ...filterParams,
                          ...{ page: currentPage - 1 },
                        });
                      }}
                      disabled={currentPage === 1}
                    >
                      Prev
                    </Button>

                    <span>
                      {currentPage} of {totalPage}
                    </span>
                    <Button
                      color="secondary"
                      onClick={() => {
                        setParams({
                          ...filterParams,
                          ...{ page: currentPage + 1 },
                        });
                      }}
                      disabled={currentPage === totalPage}
                    >
                      Next
                    </Button>
                  </div>
                </> :
                <Card.Body className="text-center justify-content-center">
                  <div className="h4">No Users</div>
                  <div className="detail mb-5">You haven’t created any users yet.</div>
                </Card.Body>
              }
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

function AddNewUser(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const { user } = useSelector(state => state.auth)
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    type: "",
    account_id: ""
  });
  const handleSubmit = (values) => {
    console.log(values)
    setLoading(true);
    if (values.id) {
      let data = {
        name: values.firstName + " " + values.lastName,
        email: values.email,
        type: values.type,
        account_id: values.account_id
      };
      userService
        .updateUser(values.id, data)
        .then((response) => {
          setLoading(false);
          setAlert({
            type: "success",
            message: "Successfully updated changed data",
          });
        })
        .catch((error) => {
          setAlert({
            type: "danger",
            message:
              (error.response && error.response.data.message) || error.message,
          });
          setLoading(false);
        });
    } else {
      values.name = values.firstName + " " + values.lastName;
      userService
        .createUser(values)
        .then((response) => {
          setLoading(false);
          history.push("/users");
        })
        .catch((error) => {
          setAlert({
            type: "danger",
            message:
              (error.response && error.response.data.message) || error.message,
          });
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (props.match.params.id) {
      setLoading(true);
      userService
        .getUserById(props.match.params.id)
        .then((response) => {
          let values = response.data.user;
          setInitialValues({
            id: values.id,
            firstName: values.name.split(" ")[0],
            lastName: values.name.split(" ")[1],
            email: values.email,
            type: values.type,
            account_id: values.account_id
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setAlert({
            type: "danger",
            message:
              (error.response && error.response.data.message) || error.message,
          });
        });
    }
  }, []);
  React.useEffect(() => {
    if (user)
      setInitialValues({
        ...initialValues,
        account_id: user.account_id
      })
  }, [user])
  const deleteUser = () => {
    setLoading(true);
    userService
      .deleteUser(initialValues.id)
      .then((response) => {
        setLoading(false);
        props.history.push("/users");
      })
      .catch((error) => {
        setLoading(false);
        setAlert({
          type: "danger",
          message:
            (error.response && error.response.data.message) || error.message,
        });
      });
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={(
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        handleSubmit(values);
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <SiteWrapper loading={loading}>
          <Page.Content>
            <Page.Header title="Users" />
            <Grid.Row cards={true}>
              <Grid.Col width={12}>
                <Card className="charge-point-card">
                  <Grid.Row>
                    <Grid.Col width={12} lg={8} className="m-auto">
                      <Card.Header className="d-flex justify-content-between">
                        <Svgicon name='user' />
                        <Card.Title>
                          {props.match.params.id ? "Edit " : "Add New "}User
                        </Card.Title>
                        <div className="ml-auto lh-1">
                          <Button
                            color="secondary"
                            onClick={() => {
                              props.match.params.id
                                ? props.history.push(
                                  "/users/" + props.match.params.id
                                )
                                : props.history.push("/users");
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Card.Header>
                    </Grid.Col>
                    <Grid.Col width={12} lg={12}>
                      <div className="border-bottom"></div>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col width={12} lg={8} className="m-auto">
                      <Card.Body className='pt-40'>
                        <form
                          onSubmit={handleSubmit}
                          autoComplete="off"
                        >
                          <Grid.Row>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="firstName"
                                  className={
                                    errors.firstName && touched.firstName
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="First Name"
                                />
                                {errors.firstName && touched.firstName && (
                                  <div className="invalid-feedback">
                                    {errors.firstName}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="lastName"
                                  className={
                                    errors.lastName && touched.lastName
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="Last Name"
                                />
                                {errors.lastName && touched.lastName && (
                                  <div className="invalid-feedback">
                                    {errors.lastName}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="email"
                                  className={
                                    errors.email && touched.email
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="Email Address"
                                />
                                {errors.email && touched.email && (
                                  <div className="invalid-feedback">
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                            <Grid.Col width={12} md={6}>
                              <div className="pb-40">
                                <CustomSelect
                                  options={[
                                    { label: 'Admin', value: 'Admin' }
                                    , { label: 'Manager', value: 'Manager' }
                                    , { label: 'Customer', value: 'Customer' }
                                  ]}
                                  placeholder='Role'
                                  onChange={(v) => values.type = v}
                                  value={values.type}
                                  error={errors}
                                />
                                {errors.type && (
                                  <div className="invalid-feedback">
                                    {errors.type}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>
                            {/* <Grid.Col width={12} md={6}>
                              <div className="form-group">
                                <Field
                                  name="account_id"
                                  className={
                                    errors.account_id && touched.account_id
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                  placeholder="Account ID"
                                  readOnly
                                />
                                {errors.account_id && touched.account_id && (
                                  <div className="invalid-feedback">
                                    {errors.account_id}
                                  </div>
                                )}
                              </div>
                            </Grid.Col> */}

                          </Grid.Row>

                          {alert.message && (
                            <Alert type={alert.type}>{alert.message}</Alert>
                          )}
                          <button
                            type="submit"
                            className="btn btn-barlows mb-4"
                            disabled={loading}
                          >
                            {props.match.params.id
                              ? "Save Changes"
                              : "Add New User"}
                          </button>
                        </form>
                      </Card.Body>
                    </Grid.Col>
                  </Grid.Row>
                  {props.match.params.id &&
                    <Grid.Row className="mt-9 delete-footer">
                      <Grid.Col width={12} lg={8} className="m-auto ">
                        <Card.Body>
                          <div className="d-flex  user-page mt-8">
                            <Button outline color="danger" onClick={handleShow}>
                              Delete User
                            </Button>
                            <span
                              style={{
                                color: "#FF4747",
                                fontSize: 11,
                                marginLeft: 20,
                              }}
                            >
                              Note: The user will no longer be able to login.
                            </span>
                          </div>
                        </Card.Body>
                        <Modal
                          show={show}
                          centered={true}
                          onHide={handleClose}
                          dialogClassName="delete-user cancel-subscription"
                        >
                          <Modal.Body>
                            <div
                              style={{ textAlign: "right", cursor: "pointer" }}
                              className='close-icon'
                            >
                              <svg
                                width="21px"
                                height="20px"
                                viewBox="0 0 32 32"
                                onClick={handleClose}
                              >
                                <g
                                  stroke="#E5E9EF"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    transform="translate(-419.000000, -240.000000)"
                                    fill="#E5E9EF"
                                  >
                                    <g
                                      id="icons"
                                      transform="translate(56.000000, 160.000000)"
                                    >
                                      <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"></polygon>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="d-flex justify-content-center align-items-center notification-content">
                              <svg width="50px" height="50px" viewBox="0 0 50 50">
                                <title>
                                  F43EE6C7-25E5-4915-8583-45C7C5200063
                                </title>
                                <g
                                  id="Desktop-App---BRANDED-V2"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    id="Settings---Delete-User-Modal"
                                    transform="translate(-695.000000, -376.000000)"
                                    fill="#FF4747"
                                  >
                                    <g
                                      id="Group-8"
                                      transform="translate(530.000000, 336.000000)"
                                    >
                                      <g
                                        id="Group-6"
                                        transform="translate(165.000000, 40.000000)"
                                      >
                                        <rect
                                          id="Rectangle"
                                          fill-opacity="0.1"
                                          x="0"
                                          y="0"
                                          width="50"
                                          height="50"
                                          rx="5"
                                        ></rect>
                                        <g
                                          id="delete"
                                          transform="translate(15.000000, 15.000000)"
                                        >
                                          <path
                                            d="M5,2 C5,0.89543 5.89543,0 7,0 L13,0 C14.1046,0 15,0.89543 15,2 L15,4 L16.9897,4 C16.9959,3.99994 17.0021,3.99994 17.0083,4 L19,4 C19.5523,4 20,4.44772 20,5 C20,5.55228 19.5523,6 19,6 L17.9311,6 L17.0638,18.1425 C16.989,19.1891 16.1182,20 15.0689,20 L4.93112,20 C3.88184,20 3.01096,19.1891 2.9362,18.1425 L2.06888,6 L1,6 C0.44772,6 0,5.55228 0,5 C0,4.44772 0.44772,4 1,4 L2.99174,4 C2.99795,3.99994 3.00414,3.99994 3.01032,4 L5,4 L5,2 Z M7,4 L13,4 L13,2 L7,2 L7,4 Z M4.07398,6 L4.93112,18 L15.0689,18 L15.926,6 L4.07398,6 Z M8,8 C8.5523,8 9,8.4477 9,9 L9,15 C9,15.5523 8.5523,16 8,16 C7.44772,16 7,15.5523 7,15 L7,9 C7,8.4477 7.44772,8 8,8 Z M12,8 C12.5523,8 13,8.4477 13,9 L13,15 C13,15.5523 12.5523,16 12,16 C11.4477,16 11,15.5523 11,15 L11,9 C11,8.4477 11.4477,8 12,8 Z"
                                            id="Shape"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              <div className="content-title">Delete User?</div>
                              <div className="content-note">
                                Are you sure you want to delete this user? The
                                user will no longer be able to login.
                              </div>
                              <div
                                className="d-flex user-page"
                                style={{ marginTop: 20 }}
                              >
                                <Button
                                  outline
                                  color="danger"
                                  onClick={deleteUser}
                                >
                                  Yes, Delete User
                                </Button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </Grid.Col>
                    </Grid.Row>}
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Page.Content>
        </SiteWrapper>
      )}
    />
  );
}

function SingleUser(props) {
  const [filterParams, setParams] = useState({
    page: 1,
  });
  const [filterParams1, setParams1] = useState({
    page: 1,
  });
  const [totalPage, setTotalPage] = useState(1);
  const [totalPage1, setTotalPage1] = useState(1);
  const [totalTransaction, setTransactionCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [stations, setStations] = useState([]);
  const [alert, setAlert] = useState({});
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    type: "",
  });
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resend, setResend] = useState(false);
  const [message, setMessage] = useState('')
  const location = useLocation();
  const options = (
    <React.Fragment>
      <Button
        color="secondary"
        onClick={() => {
          props.history.push("/users");
        }}
      >
        Back
      </Button>
    </React.Fragment>
  );
  const convertHours = (value) => {
    var n = new Date(0, 0);
    // n.setSeconds(+value * 60 * 60);
    n.setSeconds(+value);
    return n.toTimeString().slice(0, 5);
  };
  useEffect(() => {
    if (props.match.params.id) {
      setLoading(true);
      userService
        .getUserById(props.match.params.id, filterParams)
        .then((response) => {
          setUserData(response.data.user);
          setTransactionCount(response.data.transactions.total);
          setTotalPage(Math.ceil(response.data.transactions.total / 10));
          setCurrentPage(response.data.transactions.current_page);
          setTotalPage1(Math.ceil(response.data.stations.total / 10));
          setCurrentPage1(response.data.stations.current_page);
          setTransactions(response.data.transactions.data);
          let data = response.data.stations.data
            .filter(s => s.assign && s.assign.indexOf(response.data.user.id) > -1);
          setStations(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setAlert({
            type: "danger",
            message:
              (error.response && error.response.data.message) || error.message,
          });
        });
    }
  }, [filterParams, filterParams1]);
  useEffect(() => {
    console.log(location.state);
    if (location.state && location.state.detail) {
      setMessage(location.state.detail);

    }
  }, [location])
  return (
    <SiteWrapper loading={loading}>
      <Page.Content>
        {message ? (
          <Alert type='success' icon='check-circle'>
            {message}
          </Alert>
        ) : null}
        <Page.Header title="Users" options={options} />
        <Grid.Row cards={true}>
          <Grid.Col width={12} lg={6}>
            <Card className=" h-100">
              <Card.Header className="d-flex">
                <Svgicon name='user' />
                <Card.Title>Details</Card.Title>
              </Card.Header>
              <Card.Body className='user-detail-content'>
                <Grid.Row className="mb-5">
                  <Grid.Col>
                    <div className="subheader mb-3">USER NAME</div>
                    <div className="details">{userData.name}</div>
                  </Grid.Col>
                  <Grid.Col>
                    <div className="subheader mb-3">EMAIL</div>
                    <div className="details">{userData.email}</div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className="mb-5">
                  <Grid.Col>
                    <div className="subheader mb-3">ROLE</div>
                    <div className="details">{userData.type}</div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={12}>
                    {alert.message && (
                      <Alert type={alert.type}>{alert.message}</Alert>
                    )}
                  </Grid.Col>

                  <Grid.Col>
                    <Button
                      color="secondary"
                      onClick={() => {
                        props.history.push(
                          "/users/edit/" + props.match.params.id
                        );
                      }}
                    >
                      Edit
                    </Button>
                  </Grid.Col>
                  {!userData.email_verified_at && !loading && (
                    <Grid.Col>
                      <Button
                        disabled={resend}
                        color="secondary"
                        onClick={() => {
                          setResend(true);
                          userService
                            .ResendInviteEmail(props.match.params.id)
                            .then((response) => {
                              setResend(false);
                              setAlert({
                                type: "success",
                                message: response.data.message,
                              });
                            })
                            .catch((error) => {
                              setResend(false);
                              setAlert({
                                type: "danger",
                                message:
                                  (error.response &&
                                    error.response.data.message) ||
                                  error.message,
                              });
                            });
                        }}
                      >
                        Resend Invite Email
                      </Button>
                    </Grid.Col>
                  )}
                </Grid.Row>
              </Card.Body>
            </Card>
          </Grid.Col>
          <Grid.Col width={12} lg={6} className="details-card">
            <Grid.Row>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className="text-center">
                    <div className="h1 m-3">{totalTransaction}</div>
                    <div className="mb-3" style={{ color: '#74797E' }}>Transactions</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className="text-center">
                    <div className="h1 m-3">
                      {userData.total_energy_used &&
                        parseFloat(
                          userData.total_energy_used.toFixed(2) * 100
                        ) / 100}
                      kwh
                    </div>
                    <div className="mb-3" style={{ color: '#74797E' }}>Total energy used</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className="text-center">
                    <div className="h1 m-3">
                      £{userData.revenue && (userData.revenue / 100).toFixed(2)}
                    </div>
                    <div className="mb-3" style={{ color: '#74797E' }}>Revenue</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
              <Grid.Col width={6} lg={6}>
                <Card>
                  <Card.Body className="text-center padding-sm-0">
                    <div className="h1 m-3">
                      {convertHours(userData.total_charge_time)}
                    </div>
                    <div className="mb-3" style={{ color: '#74797E' }}>Total charge time (hrs)</div>
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        {userData.type === 'Customer' &&
          <Grid.Row>
            <Grid.Col>
              <Card className={stations.length === 0 ? 'user-fixed-card' : ''}>
                <Card.Header className='d-flex w-100 justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <Svgicon name='cp' />
                    <Card.Title>Charge Points</Card.Title>
                  </div>
                  {userData.type === 'Customer' && stations.length > 0 &&
                    < Button
                      color='secondary'
                      onClick={() => {
                        props.history.push("/users/assign/" + props.match.params.id);
                      }}
                      className='add-new-cp'
                    >
                      Add New <span>Charge Point</span>
                    </Button>}
                </Card.Header>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table card-table table-vcenter datatable"
                >
                  {stations.length > 0 &&
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader>LOCATION</Table.ColHeader>
                        <Table.ColHeader>ID</Table.ColHeader>
                        <Table.ColHeader>STATION-ID</Table.ColHeader>
                        <Table.ColHeader>CONNECTION FEE</Table.ColHeader>
                        <Table.ColHeader>TARIFF</Table.ColHeader>
                        <Table.ColHeader />
                      </Table.Row>
                    </Table.Header>}
                  <Table.Body>
                    {stations
                      .map((item, i) => (
                        <Table.Row key={i}>
                          <Table.Col className='text-nowrap mobile-locations'>{item.street}</Table.Col>
                          <Table.Col>{item.id}</Table.Col>
                          <Table.Col>{item.station_id}</Table.Col>
                          <Table.Col>£{item.custom[0] ? (item.custom[0].pivot.fee).toFixed(2) : (item.connection_fee).toFixed(3)}</Table.Col>
                          <Table.Col>£{item.custom[0] ? (item.custom[0].pivot.price).toFixed(2) : (item.price_per_kwh).toFixed(2)} per kwh</Table.Col>
                          <Table.Col>
                            <Button
                              color='secondary'
                              onClick={() => {
                                props.history.push("/users/assign/" + props.match.params.id + '/' + item.custom[0].pivot.id);
                              }}
                            >
                              Edit
                            </Button>
                          </Table.Col>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
                {stations.length === 0 && !loading && (
                  <Card.Body className='align-items-center justify-content-center'>
                    <h4 className="h4 mt-4 text-center" style={{ color: '#000', marginBottom: 20 }}>
                      Add Charge Point
                    </h4>
                    <p style={{ marginBottom: 20 }} className='text-center'>If you want to add custom charging tariffs for a user then click Add New Charge Point.</p>
                    {userData.type === 'Customer' &&
                      <button
                        onClick={() => props.history.push("/users/assign/" + props.match.params.id)}
                        className='btn btn-barlows mb-5'
                        style={{ maxWidth: 200 }}
                        disabled={loading}
                      >
                        Add New Charge Point
                      </button>}

                  </Card.Body>
                )}
                {stations.length > 0 && (
                  <div className="pagination-barlows">
                    <Button
                      color="secondary"
                      onClick={() => {
                        setParams1({
                          ...filterParams1,
                          ...{ page: currentPage1 - 1 },
                        });
                      }}
                      disabled={currentPage1 === 1}
                    >
                      Prev
                    </Button>

                    <span>
                      {currentPage1} of {totalPage1}
                    </span>
                    <Button
                      color="secondary"
                      onClick={() => {
                        setParams1({
                          ...filterParams1,
                          ...{ page: currentPage1 + 1 },
                        });
                      }}
                      disabled={currentPage1 === totalPage1}
                    >
                      Next
                    </Button>
                  </div>
                )}
              </Card>
            </Grid.Col>
          </Grid.Row>}

        <Grid.Row>
          <Grid.Col>
            <Card className={transactions.length === 0 ? 'user-fixed-card' : ''}>
              <Card.Header>
                <Svgicon name='transaction' />
                <Card.Title>Transactions</Card.Title>
              </Card.Header>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table card-table table-vcenter datatable"
              >
                {transactions.length > 0 &&
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>ID</Table.ColHeader>
                      <Table.ColHeader>Name</Table.ColHeader>
                      <Table.ColHeader>Date</Table.ColHeader>
                      <Table.ColHeader>Charge</Table.ColHeader>
                      <Table.ColHeader>Energy used</Table.ColHeader>
                      <Table.ColHeader>Price</Table.ColHeader>
                      <Table.ColHeader>Transaction ID</Table.ColHeader>
                    </Table.Row>
                  </Table.Header>}
                <Table.Body>
                  {transactions.map((item, i) => (
                    <Table.Row key={i}>
                      <Table.Col>{item.id}</Table.Col>
                      <Table.Col className="text-nowrap">
                        {item.user.name}
                      </Table.Col>
                      <Table.Col>
                        <Moment format="HH:mm DD MMM YYYY">{item.charge_date}</Moment>
                      </Table.Col>
                      <Table.Col>{convertHours(item.charging_time)}</Table.Col>
                      <Table.Col>
                        {parseFloat(item.total_kwh.toFixed(2) * 100) / 100}kwh
                      </Table.Col>
                      <Table.Col className="price">
                        £{(item.charge_cost / 100).toFixed(2)}
                      </Table.Col>
                      <Table.Col>
                        {item.charge_id}
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {transactions.length === 0 && !loading && (
                <Card.Body className='align-items-center justify-content-center'>
                  <h4 className="h4 mt-4 text-center" style={{ color: '#000', marginBottom: 20 }}>
                    No Transactions
                  </h4>
                  <p className='text-center  mb-4 pb-4' style={{ color: '#74797E' }}>You haven’t made any transactions yet.</p>
                </Card.Body>
              )}
              {transactions.length > 0 && (
                <div className="pagination-barlows">
                  <Button
                    color="secondary"
                    onClick={() => {
                      setParams({
                        ...filterParams,
                        ...{ page: currentPage - 1 },
                      });
                    }}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </Button>

                  <span>
                    {currentPage} of {totalPage}
                  </span>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setParams({
                        ...filterParams,
                        ...{ page: currentPage + 1 },
                      });
                    }}
                    disabled={currentPage === totalPage}
                  >
                    Next
                  </Button>
                </div>
              )}
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper >
  );
}
function AssignCPToUser(props) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [stations, setStations] = useState([]);
  const [assign, setAssign] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [initialValues, setInitialValues] = useState({
    station_id: "",
    price_per_kwh: "",
    connection_fee: "",
    type: ""
  });
  const handleRemove = () => {
    handleClose();
    let data = { ...initialValues };
    data.custom_id = props.match.params.customId;
    if (!assign) return;
    let array = [...assign];
    let index = array.indexOf(Number(props.match.params.id));
    if (index > -1) {
      array.splice(index, 1);
    }
    data.assign = array.length ? array : '';
    data.station_id = data.station_id.split(' - ')[0];
    // console.log(data); return
    setPageLoading(true);
    userService
      .unassignUser(data)
      .then((response) => {
        setPageLoading(false);
        // history.push("/users/" + props.match.params.id);
        history.push({
          pathname: "/users/" + props.match.params.id,
          state: { detail: 'User is removed from cp' }
        });
      })
      .catch((error) => {
        setAlert({
          type: "danger",
          message: (error.response && error.response.data.message) || error.message,
        });
        setPageLoading(false);
      });
  }
  const handleSubmit = (values) => {
    if (props.match.params.customId) {
      values.custom_id = props.match.params.customId;
      setPageLoading(true);
      userService
        .assignToUser(values)
        .then((response) => {
          setPageLoading(false);
          history.push({
            pathname: "/users/" + props.match.params.id,
            state: { detail: 'Charge point successfully updated to user' }
          });
        })
        .catch((error) => {
          setAlert({
            type: "danger",
            message: (error.response && error.response.data.message) || error.message,
          });
          setPageLoading(false);
        });
      return;
    }
    values.userid = props.match.params.id;
    let station = stations.find(s => s.id === Number(values.station_id));
    if (!station) return;
    if (station.assign && station.assign.indexOf(Number(props.match.params.id)) > -1) {
      setAlert({
        type: "danger",
        message: 'This staion already is added to user.',
      });
      return;
    }
    values.assign = station.assign ? [...station.assign, Number(props.match.params.id)] : [Number(props.match.params.id)];
    setPageLoading(true);
    userService
      .assignToUser(values)
      .then((response) => {
        setPageLoading(false);
        // history.push("/users/" + props.match.params.id);
        history.push({
          pathname: "/users/" + props.match.params.id,
          state: { detail: 'Charge point successfully assigned to user' }
        });
      })
      .catch((error) => {
        setAlert({
          type: "danger",
          message: (error.response && error.response.data.message) || error.message,
        });
        setPageLoading(false);
      });
  };

  useEffect(() => {
    setPageLoading(true);
    if (props.match.params.customId) {
      userService
        .getUserById(props.match.params.id, { custom_id: props.match.params.customId })
        .then((response) => {
          setInitialValues({
            station_id: response.data.stations.id + ' - ' + response.data.stations.station_id,
            type: response.data.stations.station_id,
            connection_fee: (response.data.custom.fee).toFixed(2),
            price_per_kwh: (response.data.custom.price).toFixed(2)
          })
          setAssign(response.data.stations.assign);
          setPageLoading(false);
        })
        .catch((error) => {
          setPageLoading(false);
          setAlert({
            type: "danger",
            message:
              (error.response && error.response.data.message) || error.message,
          });
        });
    } else
      userService
        .getUserById(props.match.params.id)
        .then((response) => {
          setStations(response.data.stations);
          setPageLoading(false);
        })
        .catch((error) => {
          setPageLoading(false);
          setAlert({
            type: "danger",
            message:
              (error.response && error.response.data.message) || error.message,
          });
        });
  }, []);
  let options = [];
  stations.forEach(s => {
    let obj = {};
    obj.label = s.id + ' - ' + s.station_id;
    obj.value = s.id;
    options.push(obj);
  })


  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formSchema1}
      onSubmit={(values, { setSubmitting, setErrors /* setValues and other goodies */ }) => {

        handleSubmit({
          ...values,
        });

      }}
      render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <SiteWrapper loading={pageLoading}>
          <Page.Content>
            <Page.Header title='Users' />
            <Grid.Row cards={true}>
              <Grid.Col width={12}>
                <Card className='charge-point-card'>
                  <Grid.Row>
                    <Grid.Col width={12} lg={8} className='m-auto'>
                      <Card.Header className='d-flex justify-content-between'>
                        <Svgicon name='cp' />
                        <Card.Title>{props.match.params.customId ? 'Edit User Access' : 'Add User to Charge Point'}</Card.Title>
                        <div className='ml-auto lh-1'>
                          <Button
                            color='secondary'
                            onClick={() => { props.history.push("/users/" + props.match.params.id); }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Card.Header>
                    </Grid.Col>
                    <Grid.Col width={12} lg={12}>
                      <div className='border-bottom'></div>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col width={12} lg={8} className='m-auto'>
                      <Card.Body>
                        <form onSubmit={handleSubmit} autoComplete='off' className='mt-form'>
                          {!props.match.params.customId &&
                            <Grid.Col width={12} md={6}>
                              <p style={{ marginLeft: '-0.75rem', marginBottom: 30, color: '#979797' }} className='details'>
                                Add a charge point below and set a custom charging tariff for this user.
                                This will override the default charging tariffs for the charge point.
                              </p>
                            </Grid.Col>}
                          <Grid.Row>
                            <Grid.Col width={12} md={6}>

                              <div className="form-group">
                                {props.match.params.customId ?
                                  <Field
                                    name='station_id'
                                    className={
                                      errors.station_id && touched.station_id
                                        ? "form-control is-invalid state-invalid"
                                        : "form-control"}
                                    placeholder='Charge point ID'
                                    readOnly
                                  /> :
                                  <CustomSelect
                                    options={options}
                                    placeholder='Charge Point ID'
                                    onChange={(v) => { values.station_id = v; values.type = v }}
                                    value={values.station_id}
                                    error={errors}
                                  />
                                }

                                {errors.station_id && touched.station_id && (
                                  <div className="invalid-feedback">
                                    {errors.station_id}
                                  </div>
                                )}
                              </div>
                            </Grid.Col>


                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <div className='input-group'>
                                  <span className='input-group-prepend'>
                                    <span className='input-group-text'>£</span>
                                  </span>
                                  <span
                                    className={
                                      errors.price_per_kwh && touched.price_per_kwh
                                        ? "form-control price_per_kwh is-invalid state-invalid"
                                        : "form-control price_per_kwh"
                                    }
                                  >
                                    {values.price_per_kwh && <span>£</span>}
                                    <Field
                                      name='price_per_kwh'
                                      className={values.price_per_kwh ? "" : "w-100"}
                                      placeholder='Price per kwh'
                                      style={{
                                        width: (values.price_per_kwh.toString().length + 1) * 8 + "px",
                                      }}
                                    />
                                    {values.price_per_kwh && <span>per kwh</span>}
                                  </span>
                                  <div
                                    style={{
                                      width: values.price_per_kwh.length,
                                    }}
                                  ></div>
                                  {errors.price_per_kwh && touched.price_per_kwh && <div className='invalid-feedback'>{errors.price_per_kwh}</div>}
                                </div>
                              </div>
                            </Grid.Col>

                            <Grid.Col width={12} md={6}>
                              <div className='form-group'>
                                <div className='input-group'>
                                  <span className='input-group-prepend'>
                                    <span className='input-group-text'>£</span>
                                  </span>
                                  <span
                                    className={
                                      errors.connection_fee && touched.connection_fee
                                        ? "form-control price_per_kwh is-invalid state-invalid"
                                        : "form-control price_per_kwh"
                                    }
                                  >
                                    {values.connection_fee && <span>£</span>}
                                    <Field
                                      name='connection_fee'
                                      placeholder='Connection Fee'
                                      className={values.connection_fee ? "" : "w-100"}
                                      style={{
                                        width: (values.connection_fee.toString().length + 1) * 8 + "px",
                                      }}
                                    />
                                    {values.connection_fee && <span>Connection Fee</span>}
                                  </span>
                                  {errors.connection_fee && touched.connection_fee && <div className='invalid-feedback'>{errors.connection_fee}</div>}
                                </div>
                              </div>
                            </Grid.Col>

                          </Grid.Row>

                          {alert.message && <Alert type={alert.type}>{alert.message}</Alert>}
                          <button type='submit' className='btn btn-barlows mb-4 evaa-btn' disabled={isLoading}>
                            {props.match.params.customId ? 'Edit Charge Point' : 'Add New Charge Point'}
                          </button>
                        </form>
                      </Card.Body>
                    </Grid.Col>
                  </Grid.Row>

                  {props.match.params.customId &&
                    <Grid.Row className="mt-9 delete-footer">
                      <Grid.Col width={12} lg={8} className="m-auto ">
                        <Card.Body>
                          <div className="d-flex  user-page mt-8">
                            <Button outline color="danger" onClick={handleShow}>
                              Remove Charge Point
                            </Button>
                            <span
                              style={{
                                color: "#FF4747",
                                fontSize: 11,
                                marginLeft: 20,
                              }}
                            >
                              Note: The user will no longer have a custom charging tariff.
                            </span>
                          </div>
                        </Card.Body>
                        <Modal
                          show={show}
                          centered={true}
                          onHide={handleClose}
                          dialogClassName="delete-user cancel-subscription"
                        >
                          <Modal.Body>
                            <div
                              style={{ textAlign: "right", cursor: "pointer" }}
                              className='close-icon'
                            >
                              <svg
                                width="21px"
                                height="20px"
                                viewBox="0 0 32 32"
                                onClick={handleClose}
                              >
                                <g
                                  stroke="#E5E9EF"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    transform="translate(-419.000000, -240.000000)"
                                    fill="#E5E9EF"
                                  >
                                    <g
                                      id="icons"
                                      transform="translate(56.000000, 160.000000)"
                                    >
                                      <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"></polygon>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="d-flex justify-content-center align-items-center notification-content">
                              <svg width="50px" height="50px" viewBox="0 0 50 50">
                                <title>
                                  F43EE6C7-25E5-4915-8583-45C7C5200063
                                </title>
                                <g
                                  id="Desktop-App---BRANDED-V2"
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <g
                                    id="Settings---Delete-User-Modal"
                                    transform="translate(-695.000000, -376.000000)"
                                    fill="#FF4747"
                                  >
                                    <g
                                      id="Group-8"
                                      transform="translate(530.000000, 336.000000)"
                                    >
                                      <g
                                        id="Group-6"
                                        transform="translate(165.000000, 40.000000)"
                                      >
                                        <rect
                                          id="Rectangle"
                                          fill-opacity="0.1"
                                          x="0"
                                          y="0"
                                          width="50"
                                          height="50"
                                          rx="5"
                                        ></rect>
                                        <g
                                          id="delete"
                                          transform="translate(15.000000, 15.000000)"
                                        >
                                          <path
                                            d="M5,2 C5,0.89543 5.89543,0 7,0 L13,0 C14.1046,0 15,0.89543 15,2 L15,4 L16.9897,4 C16.9959,3.99994 17.0021,3.99994 17.0083,4 L19,4 C19.5523,4 20,4.44772 20,5 C20,5.55228 19.5523,6 19,6 L17.9311,6 L17.0638,18.1425 C16.989,19.1891 16.1182,20 15.0689,20 L4.93112,20 C3.88184,20 3.01096,19.1891 2.9362,18.1425 L2.06888,6 L1,6 C0.44772,6 0,5.55228 0,5 C0,4.44772 0.44772,4 1,4 L2.99174,4 C2.99795,3.99994 3.00414,3.99994 3.01032,4 L5,4 L5,2 Z M7,4 L13,4 L13,2 L7,2 L7,4 Z M4.07398,6 L4.93112,18 L15.0689,18 L15.926,6 L4.07398,6 Z M8,8 C8.5523,8 9,8.4477 9,9 L9,15 C9,15.5523 8.5523,16 8,16 C7.44772,16 7,15.5523 7,15 L7,9 C7,8.4477 7.44772,8 8,8 Z M12,8 C12.5523,8 13,8.4477 13,9 L13,15 C13,15.5523 12.5523,16 12,16 C11.4477,16 11,15.5523 11,15 L11,9 C11,8.4477 11.4477,8 12,8 Z"
                                            id="Shape"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              <div className="content-title">Delete Charge Point?</div>
                              <div className="content-note">
                                Are you sure you want to delete charge point? The user will no longer have a custom charging tariff.
                              </div>
                              <div
                                className="d-flex user-page"
                                style={{ marginTop: 20 }}
                              >
                                <Button
                                  outline
                                  color="danger"
                                  onClick={handleRemove}
                                >
                                  Yes, Delete Charge Point
                                </Button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </Grid.Col>
                    </Grid.Row>
                  }

                </Card>
              </Grid.Col>
            </Grid.Row>
          </Page.Content>
        </SiteWrapper>
      )}
    />
  );
}
export default UsersPage;
export { AddNewUser, SingleUser, AssignCPToUser };
