import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Page, Grid, Card, Table, Alert, Progress, Button } from "tabler-react";
import ReactApexChart from "react-apexcharts";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from "react-router-dom";
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import * as billingServices from "../store/services/billServices";
import images from 'react-payment-inputs/images';
import SiteWrapper from "../SiteWrapper";
import { authLogin } from "../store/actions/authActions";
import Svgicon from "../components/SvgIcon";
import moment from 'moment'
import { FRONTENDURL } from "../store/services/type";
// import * as userService from "./store/services/userService";
// import * as stationService from "./store/services/stationsServices";
// import * as uptimeService from "./store/services/uptimeServices";

const formSchema = Yup.object().shape({
    cardname: Yup.string().required("There is an issue with the card. Please contact your provider."),
    cardNumber: Yup.string().required("There is an issue with the card. Please contact your provider."),
    expiryDate: Yup.string().required("There is an issue with the card. Please contact your provider."),
    cvc: Yup.string().required("There is an issue with the card. Please contact your provider."),
});

function BillingPage(props) {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([])
    const [totalSeats, setTotalSeats] = useState(0);
    const [subSeats, setSubSeats] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const { user, authToken } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCancel = () => {
        setLoading(true);
        billingServices.cancelSub()
            .then(res => {
                setLoading(false)
            })
    }
    useEffect(() => {
        setLoading(true);
        billingServices.getPaymentMethods()
            .then(res => {
                setPaymentMethods(res.data.data);
                setInvoices(res.data.invoices ? res.data.invoices.data : []);
                setTotalSeats(res.data.totalSeats);
                setSubSeats(res.data.sub ? res.data.sub.items.data[0].quantity : 0);
                let data = {
                    user: res.data.user,
                    token: authToken
                }
                dispatch(authLogin(data));
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
        billingServices.checkSubscription()
            .then((res) => {
                if (res.data.sub === 'canceled' || res.data.sub === 'failed') {
                    setShowAlert(res.data.sub);
                }
            })
            .catch((error) => {
            });
    }, [])
    if (!user) return <div />
    return (
        <SiteWrapper loading={loading}>
            <Page.Content>
                {
                    showAlert ?
                        showAlert === 'canceled' ?
                            <Alert type='danger-chargebox' icon='x-circle'>
                                Your subscription has been cancelled. Please go the billing screen and select a package to reactivate your subscription.
                            </Alert> :
                            <Alert type='danger-chargebox' icon='x-circle'>
                                <span>
                                    Your subscription is currently inactive. You will no longer be able to access the software from the end of the current billing cycle. <a href={`${FRONTENDURL}/billing/plan`}>Click here</a> to update your subscription and continue using Evaa.
                                </span>
                            </Alert> : null
                }
                <div className="page-header">
                    <h1 className="page-title d-flex w-100 justify-content-between mb-2">
                        Billing
                    </h1>
                </div>
                <Grid.Row className='billing-cards' cards={true}>
                    <Grid.Col width={12} lg={3} md={6}>
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="subheader">TOTAL CHARGE POINTS</div>
                                </div>
                                <div className="d-flex mb-5 mt-2 align-items-baseline">
                                    <div className="h1 mb-3">{totalSeats}</div>

                                </div>

                            </div>
                        </div>
                    </Grid.Col>
                    <Grid.Col width={12} lg={3} md={6}>
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="subheader">TOTAL SUBSCRIPTION CHARGE POINTS</div>
                                </div>
                                <div className="d-flex mb-5 mt-2 align-items-baseline">
                                    <div className="h1 mb-3">{subSeats * 5}</div>
                                </div>
                            </div>
                        </div>
                    </Grid.Col>
                    <Grid.Col width={12} lg={3} md={6}>
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="subheader">TOTAL COST</div>
                                </div>
                                <div className="d-flex  mt-2 align-items-baseline">
                                    <div className="h1 mb-3">{user.package === 'f' ? '£' + subSeats * 50 : user.package === 'e' ? '£' + subSeats * 25 : 0}</div>
                                </div>
                            </div>
                        </div>
                    </Grid.Col>
                    <Grid.Col width={12} lg={3} md={6}>
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div className="subheader">YOUR PLAN</div>
                                    < Button
                                        color='secondary'
                                        onClick={() => {
                                            props.history.push("/billing/plan");
                                        }}
                                        className='add-new-cp position-absolute right-10'
                                    >
                                        Edit
                                    </Button>
                                </div>
                                <div className="d-flex  mt-2 align-items-baseline">
                                    <div className="h1 mb-3">{user.package === 'b' ? 'Basic' : user.package === 'e' ? 'Enhanced' : user.package === '' ? 'Cancelled' : 'Full'}</div>
                                </div>
                            </div>
                        </div>
                    </Grid.Col>


                    <Grid.Col width={12} lg={6}>
                        <Card className='fixed-card'>
                            <Card.Header className="d-flex w-100 justify-content-between">
                                <div className='d-flex align-items-center'>
                                    <Svgicon name='payment' />
                                    <Card.Title>Payment Methods</Card.Title>
                                </div>
                                {paymentMethods.length > 0 &&
                                    < Button
                                        color='secondary'
                                        onClick={() => {
                                            props.history.push("/payment-method/new");
                                        }}
                                        className='add-new-cp'
                                    >
                                        Add New <span>Payment Method</span>
                                    </Button>
                                }
                            </Card.Header>
                            {paymentMethods.length > 0 ?
                                <Table cards={true} striped={true} responsive={true} className='table card-table table-vcenter datatable'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.ColHeader>CardHolder Name</Table.ColHeader>
                                            <Table.ColHeader>Card NO.</Table.ColHeader>
                                            <Table.ColHeader></Table.ColHeader>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {paymentMethods.map((item, i) => (
                                            <Table.Row key={i}>
                                                <Table.Col className='bill-name'>{item.name}</Table.Col>
                                                <Table.Col>&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {item.last4}</Table.Col>
                                                <Table.Col>
                                                    <Button
                                                        color='secondary'
                                                        onClick={() => {
                                                            props.history.push({
                                                                pathname: "/payment-method/new/" + item.id,
                                                                state: item
                                                            });
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </Table.Col>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table> :
                                <Card.Body className="text-center align-items-center justify-content-center">
                                    <div className="h4" style={{ marginBottom: 20 }}>Add Payment Method</div>
                                    <div className="detail" style={{ marginBottom: 20 }}>You don't have a payment method setup yet. Add one to start charging.</div>
                                    <button
                                        onClick={() => props.history.push('payment-method/new')}
                                        className='btn btn-barlows add-new-cp'
                                        style={{ maxWidth: 200 }}
                                        disabled={loading}
                                    >
                                        Add New<span> Payment Method</span>
                                    </button>
                                </Card.Body>
                            }
                        </Card>
                    </Grid.Col>
                    <Grid.Col width={12} lg={6}>
                        <Card className='fixed-card'>
                            <Card.Header className="d-flex">
                                <Svgicon name='invoice' />
                                <Card.Title>Invoices</Card.Title>
                            </Card.Header>
                            {invoices.length > 0 ?
                                <Table cards={true} striped={true} responsive={true} className='table card-table table-vcenter datatable'>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.ColHeader>INVOICE NO.</Table.ColHeader>
                                            <Table.ColHeader>DATE</Table.ColHeader>
                                            <Table.ColHeader>AMOUNT</Table.ColHeader>
                                            <Table.ColHeader></Table.ColHeader>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {invoices.map((item, i) => (
                                            <Table.Row key={i}>
                                                <Table.Col className='bill-name'>{item.number}</Table.Col>
                                                <Table.Col>{moment.unix(item.period_end).format('DD MMM YYYY')}</Table.Col>
                                                <Table.Col>£{(item.total / 100).toFixed(2)}</Table.Col>
                                                <Table.Col>
                                                    <Button
                                                        color='secondary'
                                                        onClick={() => {
                                                            window.open(item.hosted_invoice_url)
                                                        }}
                                                    >
                                                        View
                                                    </Button>
                                                </Table.Col>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table> :
                                <Card.Body className="text-center justify-content-center">
                                    <div className="h4">No Invoices</div>
                                    <div className="detail">You haven’t received any invoices yet.</div>
                                </Card.Body>
                            }
                        </Card>
                    </Grid.Col>
                </Grid.Row>
                {/* <Grid.Row>
                    <Grid.Col lg={12}>
                        <div className="d-flex  user-page mt-20 mb-40">
                            <Button outline color="danger" onClick={handleShow}>
                                Cancel Subscription
                            </Button>
                            <span
                                style={{
                                    color: "#FF4747",
                                    fontSize: 11,
                                    marginLeft: 20,
                                }}
                            >
                                Note: You will no longer be able to use our services.
                            </span>
                        </div>
                        <Modal
                            show={show}
                            centered={true}
                            onHide={handleClose}
                            dialogClassName="delete-user cancel-subscription"
                        >
                            <Modal.Body>
                                <div
                                    style={{ textAlign: "right", cursor: "pointer" }}
                                    className='close-icon'
                                >
                                    <svg
                                        width="21px"
                                        height="20px"
                                        viewBox="0 0 32 32"
                                        onClick={handleClose}
                                    >
                                        <g
                                            stroke="#E5E9EF"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                        >
                                            <g
                                                transform="translate(-419.000000, -240.000000)"
                                                fill="#E5E9EF"
                                            >
                                                <g
                                                    id="icons"
                                                    transform="translate(56.000000, 160.000000)"
                                                >
                                                    <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"></polygon>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="d-flex justify-content-center align-items-center notification-content">
                                    <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1">
                                        <title>3984CBBC-0A4C-4907-8746-183D9B39F15D</title>
                                        <g id="Evaa---Desktop-App" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="Billing---Cancel-Modal" transform="translate(-695.000000, -346.000000)">
                                                <g id="Group-8" transform="translate(530.000000, 306.000000)">
                                                    <g id="Group-6" transform="translate(165.000000, 40.000000)">
                                                        <rect id="Rectangle" fill-opacity="0.1" fill="#FF4747" x="0" y="0" width="50" height="50" rx="5"></rect>
                                                        <g id="credit_card_off_black_24dp" transform="translate(13.000000, 13.000000)">
                                                            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                                                            <path d="M6.83,4 L20,4 C21.11,4 22,4.89 22,6 L22,18 C22,18.34 21.92,18.66 21.77,18.94 L20,17.17 L20,12 L14.83,12 L10.83,8 L20,8 L20,6 L8.83,6 L6.83,4 Z M20.49,23.31 L17.17,20 L4,20 C2.89,20 2,19.11 2,18 L2.01,6 C2.01,5.66 2.09,5.34 2.24,5.07 L0.69,3.51 L2.1,2.1 L21.9,21.9 L20.49,23.31 Z M4,6.83 L4,8 L5.17,8 L4,6.83 Z M15.17,18 L9.17,12 L4,12 L4,18 L15.17,18 Z" id="Shape" fill="#FF4747" fill-rule="nonzero"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <div className="content-title">Cancel Subscription?</div>
                                    <div className="content-note">
                                        Are you sure you want to cancel your subscription? All your data
                                        will be deleted and your won't be able to access your account
                                    </div>
                                    <div
                                        className="d-flex user-page"
                                        style={{ marginTop: 20 }}
                                    >
                                        <Button
                                            outline
                                            color="danger"
                                            onClick={handleCancel}
                                        >
                                            Yes, Cancel
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Grid.Col>
                </Grid.Row> */}
            </Page.Content>
        </SiteWrapper>
    )
}

function AddNewPayment(props) {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({
        cardname: ""
    });
    const [numberValid, setNumberValid] = useState(false);
    const [exipryValid, setExpiryValid] = useState(false);
    const [cvcValid, setCVCValid] = useState(false);
    const [show, setShow] = useState(false);
    const [alert, setAlert] = useState({});
    const {
        wrapperProps,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        meta
    } = usePaymentInputs();
    const styles = {
        inputWrapper: {
            base: {
                height: 40,
                border: '1px solid rgba(0, 40, 100, 0.12)',
                borderRadius: 5,
                boxShadow: 'none'
            },
            errored: {
                border: '1px solid #cd201f',
                boxShadow: 'none'
            }
        }
    }
    const errorStyles = {
        inputWrapper: {
            base: {
                height: 40,
                border: '1px solid #cd201f',
                borderRadius: 5,
                boxShadow: 'none'
            },
            errored: {
                border: '1px solid #cd201f',
                boxShadow: 'none'
            }
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCardNumberError = (error, erroredInputs) => {
        if (error) {
            setNumberValid(false);
        } else {
            setNumberValid(true)
        }
    }
    const handleExpiryError = (error, erroredInputs) => {
        if (error) {
            setExpiryValid(false);
        } else {
            setExpiryValid(true)
        }
    }
    const handleCVCError = (error, erroredInputs) => {
        if (error) {
            setCVCValid(false);
        } else {
            setCVCValid(true)
        }
    }

    const handleDelete = () => {
        setShow(false);
        if (location.state && props.match.params.id) {
            setLoading(true);
            billingServices.removeMethod(props.match.params.id)
                .then((response) => {
                    setLoading(false);
                    props.history.push("/billing");
                })
                .catch((error) => {
                    setAlert({
                        type: "danger",
                        message: (error.response && error.response.data.message) || error.message,
                    });
                    setLoading(false);
                });
        } else {
            setAlert({
                type: "danger",
                message: "You can't delete current payment method.",
            });
        }
    }

    const handleSubmit = (values) => {
        const { cardNumber, expiryDate, cvc } = values;
        if (!cardNumber || !expiryDate || !cvc || !numberValid || !exipryValid || !cvcValid) {
            return;
        }
        values.year = Number(values.expiryDate.split(' / ')[1]);
        values.month = Number(values.expiryDate.split(' / ')[0]);
        console.log(values);
        setLoading(true);
        if (location.state && props.match.params.id) {
            values.id = location.state.payment_id;
            billingServices.updatePaymentMethod(values)
                .then((response) => {
                    setLoading(false);
                    props.history.push("/billing");
                })
                .catch((error) => {
                    setAlert({
                        type: "danger",
                        message: (error.response && error.response.data.message) || error.message,
                    });
                    setLoading(false);
                });
            return;
        }
        billingServices.addPaymentMothod(values)
            .then((response) => {
                setLoading(false);
                props.history.push("/billing");
            })
            .catch((error) => {
                setAlert({
                    type: "danger",
                    message: (error.response && error.response.data.message) || error.message,
                });
                setLoading(false);
            });
    }
    useEffect(() => {
        console.log(location.state);
        if (location.state) {
            setInitialValues({
                cardname: location.state.name,
            })
        }
    }, [])
    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={(
                values,
                { setSubmitting, setErrors /* setValues and other goodies */ }
            ) => {
                handleSubmit(values);
            }}
            render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,

            }) => (
                <SiteWrapper loading={loading}>
                    <Page.Content>
                        <Page.Header title="Billing" />
                        <Grid.Row cards={true}>
                            <Grid.Col width={12}>
                                <Card className="charge-point-card">
                                    <Grid.Row>
                                        <Grid.Col width={12} lg={8} className="m-auto">
                                            <Card.Header className="d-flex justify-content-between">
                                                <Svgicon name='payment' />
                                                <Card.Title className='new-payment-title'>
                                                    {props.match.params.id ?
                                                        "Edit " :
                                                        <><span>Add </span>New </>}Payment Method
                                                </Card.Title>
                                                <div className="ml-auto lh-1">
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => props.history.push("/billing")}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </Card.Header>
                                        </Grid.Col>
                                        <Grid.Col width={12} lg={12}>
                                            <div className="border-bottom"></div>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Col width={12} lg={8} className="m-auto">
                                            <Card.Body className='pt-40'>
                                                <form
                                                    onSubmit={handleSubmit}
                                                    autoComplete="off"
                                                >
                                                    <Grid.Row>
                                                        <Grid.Col width={12} md={6}>
                                                            <div className="form-group">
                                                                <Field
                                                                    name="cardname"
                                                                    className={
                                                                        errors.cardname && touched.cardname
                                                                            ? "form-control is-invalid state-invalid"
                                                                            : "form-control"
                                                                    }
                                                                    placeholder="Cardholder Name"
                                                                />
                                                                {errors.cardname && touched.cardname && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.cardname}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Grid.Col>
                                                        <Grid.Col width={12} md={6}>
                                                            <PaymentInputsWrapper {...wrapperProps} styles={errors.cardNumber ? errorStyles : styles} style={{ width: '100%' }}>
                                                                <svg {...getCardImageProps({ images })} />
                                                                <input {...getCardNumberProps({ onBlur: handleBlur, onChange: handleChange, onError: handleCardNumberError })} />
                                                                <input {...getExpiryDateProps({ onBlur: handleBlur, onChange: handleChange, onError: handleExpiryError })} />
                                                                <input {...getCVCProps({ onBlur: handleBlur, onChange: handleChange, onError: handleCVCError })} />
                                                            </PaymentInputsWrapper>
                                                        </Grid.Col>

                                                    </Grid.Row>

                                                    {alert.message && (
                                                        <Alert type={alert.type}>{alert.message}</Alert>
                                                    )}
                                                    <div>
                                                    By providing your card information, you allow Evaa to charge your card for future payments in accordance with our <a style={{color: '#000', fontWeight: 'bold'}} target={'_blank'} href="https://evaa.app/terms/">Terms of Service</a>.
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-barlows mt-40 mb-4"
                                                        disabled={loading}
                                                    >
                                                        {props.match.params.id
                                                            ? "Edit Payment Method"
                                                            : "Add New Payment Method"}
                                                    </button>
                                                </form>
                                            </Card.Body>
                                        </Grid.Col>
                                    </Grid.Row>
                                    {props.match.params.id &&
                                        <Grid.Row className="mt-9 delete-footer">
                                            <Grid.Col width={12} lg={8} className="m-auto ">
                                                <Card.Body>
                                                    <div className="d-flex  user-page mt-8">
                                                        <Button outline color="danger" onClick={handleShow}>
                                                            Delete Payment Method
                                                        </Button>
                                                        <span
                                                            style={{
                                                                color: "#FF4747",
                                                                fontSize: 11,
                                                                marginLeft: 20,
                                                            }}
                                                        >
                                                            Note: The user will no longer be able to use this payment method.
                                                        </span>
                                                    </div>
                                                </Card.Body>
                                                <Modal
                                                    show={show}
                                                    centered={true}
                                                    onHide={handleClose}
                                                    dialogClassName="delete-user cancel-subscription"
                                                >
                                                    <Modal.Body>
                                                        <div
                                                            style={{ textAlign: "right", cursor: "pointer" }}
                                                            className='close-icon'
                                                        >
                                                            <svg
                                                                width="21px"
                                                                height="20px"
                                                                viewBox="0 0 32 32"
                                                                onClick={handleClose}
                                                            >
                                                                <g
                                                                    stroke="#E5E9EF"
                                                                    stroke-width="1"
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                >
                                                                    <g
                                                                        transform="translate(-419.000000, -240.000000)"
                                                                        fill="#E5E9EF"
                                                                    >
                                                                        <g
                                                                            id="icons"
                                                                            transform="translate(56.000000, 160.000000)"
                                                                        >
                                                                            <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"></polygon>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div className="d-flex justify-content-center align-items-center notification-content">
                                                            <svg width="50px" height="50px" viewBox="0 0 50 50">
                                                                <title>
                                                                    F43EE6C7-25E5-4915-8583-45C7C5200063
                                                                </title>
                                                                <g
                                                                    id="Desktop-App---BRANDED-V2"
                                                                    stroke="none"
                                                                    stroke-width="1"
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                >
                                                                    <g
                                                                        id="Settings---Delete-User-Modal"
                                                                        transform="translate(-695.000000, -376.000000)"
                                                                        fill="#FF4747"
                                                                    >
                                                                        <g
                                                                            id="Group-8"
                                                                            transform="translate(530.000000, 336.000000)"
                                                                        >
                                                                            <g
                                                                                id="Group-6"
                                                                                transform="translate(165.000000, 40.000000)"
                                                                            >
                                                                                <rect
                                                                                    id="Rectangle"
                                                                                    fill-opacity="0.1"
                                                                                    x="0"
                                                                                    y="0"
                                                                                    width="50"
                                                                                    height="50"
                                                                                    rx="5"
                                                                                ></rect>
                                                                                <g
                                                                                    id="delete"
                                                                                    transform="translate(15.000000, 15.000000)"
                                                                                >
                                                                                    <path
                                                                                        d="M5,2 C5,0.89543 5.89543,0 7,0 L13,0 C14.1046,0 15,0.89543 15,2 L15,4 L16.9897,4 C16.9959,3.99994 17.0021,3.99994 17.0083,4 L19,4 C19.5523,4 20,4.44772 20,5 C20,5.55228 19.5523,6 19,6 L17.9311,6 L17.0638,18.1425 C16.989,19.1891 16.1182,20 15.0689,20 L4.93112,20 C3.88184,20 3.01096,19.1891 2.9362,18.1425 L2.06888,6 L1,6 C0.44772,6 0,5.55228 0,5 C0,4.44772 0.44772,4 1,4 L2.99174,4 C2.99795,3.99994 3.00414,3.99994 3.01032,4 L5,4 L5,2 Z M7,4 L13,4 L13,2 L7,2 L7,4 Z M4.07398,6 L4.93112,18 L15.0689,18 L15.926,6 L4.07398,6 Z M8,8 C8.5523,8 9,8.4477 9,9 L9,15 C9,15.5523 8.5523,16 8,16 C7.44772,16 7,15.5523 7,15 L7,9 C7,8.4477 7.44772,8 8,8 Z M12,8 C12.5523,8 13,8.4477 13,9 L13,15 C13,15.5523 12.5523,16 12,16 C11.4477,16 11,15.5523 11,15 L11,9 C11,8.4477 11.4477,8 12,8 Z"
                                                                                        id="Shape"
                                                                                    ></path>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            <div className="content-title">Delete Payment Method?</div>
                                                            <div className="content-note">
                                                                Are you sure you want to delete this payment method?
                                                            </div>
                                                            <div
                                                                className="d-flex user-page"
                                                                style={{ marginTop: 20 }}
                                                            >
                                                                <Button
                                                                    outline
                                                                    color="danger"
                                                                    onClick={handleDelete}
                                                                >
                                                                    Yes, Delete Payment Method
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                            </Grid.Col>
                                        </Grid.Row>}
                                </Card>
                            </Grid.Col>
                        </Grid.Row>
                    </Page.Content>
                </SiteWrapper>
            )}
        />
    );
}
function EditPlan(props) {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({
        cardname: ""
    });
    const [numberValid, setNumberValid] = useState(false);
    const [exipryValid, setExpiryValid] = useState(false);
    const [cvcValid, setCVCValid] = useState(false);
    const [show, setShow] = useState(false);
    const [alert, setAlert] = useState({});
    const [plan, setPlan] = useState('');
    const { user } = useSelector(state => state.auth)



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleCancel = () => {
        handleClose();
        setLoading(true);
        billingServices.cancelSub()
            .then(res => {
                setLoading(false);
                props.history.push('/billing');
            })
            .catch(error => {
                setAlert({
                    type: "danger",
                    message: (error.response && error.response.data.message) || error.message,
                });
                setLoading(false)
            })
    }

    const handlePlan = (p) => {
        if (p === user.package) {
            return;
        }
        if (p !== plan) {
            setPlan(p);
            return;
        }
        setLoading(true);
        billingServices.updateSubscription({ plan: p })
            .then(res => {
                setLoading(false);
                props.history.push('/billing');
            }).catch(error => {
                setAlert({
                    type: "danger",
                    message: (error.response && error.response.data.message) || error.message,
                });
                setLoading(false)
            })
    }
    if (!user) return <div />
    return (

        <SiteWrapper loading={loading}>
            <Page.Content>
                <Page.Header title="Billing" />
                <Grid.Row cards={true}>
                    <Grid.Col width={12}>
                        <Card className="charge-point-card p-100 position-relative">
                            <div
                                style={{
                                    textAlign: "right",
                                    cursor: "pointer",
                                    position: 'absolute',
                                    top: 20,
                                    right: 20
                                }}
                                className='close-icon'
                            >
                                <svg
                                    width="21px"
                                    height="20px"
                                    viewBox="0 0 32 32"
                                    onClick={() => props.history.push('/billing')}
                                >
                                    <g
                                        stroke="#E5E9EF"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                    >
                                        <g
                                            transform="translate(-419.000000, -240.000000)"
                                            fill="#E5E9EF"
                                        >
                                            <g
                                                id="icons"
                                                transform="translate(56.000000, 160.000000)"
                                            >
                                                <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="plan-title">Change Your Plan</div>
                            <span className="plan-sub-title">Choose your new plan.</span>
                            <div className="plan-row">
                                <div
                                    // onClick={() => handlePlan('b')}
                                    className={user.package === 'b' ? "plan-card current" : "plan-card"}
                                    style={{ backgroundColor: plan === 'b' ? '#000' : '#fff' }}
                                >
                                    <>
                                        {plan === 'b' ?
                                            <p style={{ color: '#fff' }} className="plan-text1">Confirm Upgrade</p> :
                                            <p className="plan-text1">Basic - Free</p>
                                        }
                                        {plan !== 'b' && <span className="plan-text2">PORT PER MONTH</span>}
                                        {plan === 'b' ?
                                            <div className="plan-text3" style={{ textAlign: 'center', color: '#fff' }}>
                                                You are about to upgrade<br />
                                                your plan to the Basic Plan,<br />
                                                please confirm below
                                                <br /><br />
                                            </div> :
                                            <div className="plan-text3" style={{ textAlign: 'center' }}>
                                                Connect any OCPP charger<br />
                                                20% per transaction<br />
                                                Up to 5 charge points<br />
                                                Basic features
                                            </div>}
                                    </>

                                    {user.package === 'b' ?
                                        < Button
                                            color='secondary'
                                            className='add-new-cp'
                                        >
                                            Current Plan
                                        </Button> :
                                        <button onClick={() => handlePlan('b')} type='submit' className={plan === 'b' ? 'btn btn-barlows white-btn' : 'btn btn-barlows'}>
                                            {plan === 'b' ? 'Confirm' : 'Upgrade'}
                                        </button>
                                    }
                                </div>
                                <div
                                    // onClick={() => handlePlan('e')}
                                    className={user.package === 'e' ? "plan-card current" : "plan-card"}
                                    style={{ backgroundColor: plan === 'e' ? '#000' : '#fff' }}
                                >
                                    <>
                                        {plan === 'e' ?
                                            <p style={{ color: '#fff' }} className="plan-text1">Confirm Upgrade</p> :
                                            <p className="plan-text1">Enhanced - £5</p>
                                        }
                                        {plan !== 'e' && <span className="plan-text2">PORT PER MONTH</span>}
                                        {plan === 'e' ?
                                            <div className="plan-text3" style={{ textAlign: 'center', color: '#fff' }}>
                                                You are about to upgrade<br />
                                                your plan to the Enhanced Plan,<br />
                                                please confirm below
                                                <br /><br />
                                            </div> :
                                            <div className="plan-text3" style={{ textAlign: 'center' }}>
                                                Connect any OCPP charger<br />
                                                10% per transaction<br />
                                                <br /><br />
                                            </div>}
                                    </>

                                    {user.package === 'e' ?
                                        < Button
                                            color='secondary'
                                            className='add-new-cp'
                                        >
                                            Current Plan
                                        </Button> :
                                        <button onClick={() => handlePlan('e')} type='submit' className={plan === 'e' ? 'btn btn-barlows white-btn' : 'btn btn-barlows'}>
                                            {plan === 'e' ? 'Confirm' : 'Upgrade'}
                                        </button>
                                    }
                                </div>
                                <div
                                    // onClick={() => handlePlan('f')}
                                    className={user.package === 'f' ? "plan-card current" : "plan-card"}
                                    style={{ backgroundColor: plan === 'f' ? '#000' : '#fff' }}
                                >
                                    <>
                                        {plan === 'f' ?
                                            <p style={{ color: '#fff' }} className="plan-text1">Confirm Upgrade</p> :
                                            <p className="plan-text1">Full - £10</p>
                                        }
                                        {plan !== 'f' && <span className="plan-text2">PORT PER MONTH</span>}
                                        {plan === 'f' ?
                                            <div className="plan-text3" style={{ textAlign: 'center', color: '#fff' }}>
                                                You are about to upgrade<br />
                                                your plan to the Full Plan,<br />
                                                please confirm below
                                                <br /><br />
                                            </div> :
                                            <div className="plan-text3" style={{ textAlign: 'center' }}>
                                                Connect any OCPP charger<br />
                                                5% per transaction<br />
                                                <br /><br />
                                            </div>
                                        }
                                    </>

                                    {user.package === 'f' ?
                                        < Button
                                            color='secondary'
                                            className='add-new-cp'
                                        >
                                            Current Plan
                                        </Button> :
                                        <button onClick={() => handlePlan('f')} type='submit' className={plan === 'f' ? 'btn btn-barlows white-btn' : 'btn btn-barlows'}>
                                            {plan === 'f' ? 'Confirm' : 'Upgrade'}
                                        </button>
                                    }
                                </div>
                            </div>
                            {alert.message && (
                                <Alert type={alert.type}>{alert.message}</Alert>
                            )}
                        </Card>
                        <Grid.Row>
                            <Grid.Col lg={12}>
                                <div className="d-flex  user-page mt-10 mb-40">
                                    <Button outline color="danger" onClick={handleShow}>
                                        Cancel Subscription
                                    </Button>
                                    <span
                                        style={{
                                            color: "#FF4747",
                                            fontSize: 11,
                                            marginLeft: 20,
                                        }}
                                    >
                                        Note: You will no longer be able to use our services.
                                    </span>
                                </div>
                                <Modal
                                    show={show}
                                    centered={true}
                                    onHide={handleClose}
                                    dialogClassName="delete-user cancel-subscription"
                                >
                                    <Modal.Body>
                                        <div
                                            style={{ textAlign: "right", cursor: "pointer" }}
                                            className='close-icon'
                                        >
                                            <svg
                                                width="21px"
                                                height="20px"
                                                viewBox="0 0 32 32"
                                                onClick={handleClose}
                                            >
                                                <g
                                                    stroke="#E5E9EF"
                                                    stroke-width="1"
                                                    fill="none"
                                                    fill-rule="evenodd"
                                                >
                                                    <g
                                                        transform="translate(-419.000000, -240.000000)"
                                                        fill="#E5E9EF"
                                                    >
                                                        <g
                                                            id="icons"
                                                            transform="translate(56.000000, 160.000000)"
                                                        >
                                                            <polygon points="375.0183 90 384 98.554 382.48065 100 373.5 91.446 364.5183 100 363 98.554 371.98065 90 363 81.446 364.5183 80 373.5 88.554 382.48065 80 384 81.446"></polygon>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center notification-content">
                                            <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1">
                                                <title>3984CBBC-0A4C-4907-8746-183D9B39F15D</title>
                                                <g id="Evaa---Desktop-App" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="Billing---Cancel-Modal" transform="translate(-695.000000, -346.000000)">
                                                        <g id="Group-8" transform="translate(530.000000, 306.000000)">
                                                            <g id="Group-6" transform="translate(165.000000, 40.000000)">
                                                                <rect id="Rectangle" fill-opacity="0.1" fill="#FF4747" x="0" y="0" width="50" height="50" rx="5"></rect>
                                                                <g id="credit_card_off_black_24dp" transform="translate(13.000000, 13.000000)">
                                                                    <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                                                                    <path d="M6.83,4 L20,4 C21.11,4 22,4.89 22,6 L22,18 C22,18.34 21.92,18.66 21.77,18.94 L20,17.17 L20,12 L14.83,12 L10.83,8 L20,8 L20,6 L8.83,6 L6.83,4 Z M20.49,23.31 L17.17,20 L4,20 C2.89,20 2,19.11 2,18 L2.01,6 C2.01,5.66 2.09,5.34 2.24,5.07 L0.69,3.51 L2.1,2.1 L21.9,21.9 L20.49,23.31 Z M4,6.83 L4,8 L5.17,8 L4,6.83 Z M15.17,18 L9.17,12 L4,12 L4,18 L15.17,18 Z" id="Shape" fill="#FF4747" fill-rule="nonzero"></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <div className="content-title">Cancel Subscription?</div>
                                            <div className="content-note">
                                                Are you sure you want to cancel your subscription? All your data
                                                will be deleted and your won't be able to access your account
                                            </div>
                                            <div
                                                className="d-flex user-page"
                                                style={{ marginTop: 20 }}
                                            >
                                                <Button
                                                    outline
                                                    color="danger"
                                                    onClick={handleCancel}
                                                >
                                                    Yes, Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>

                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    );
}

export default BillingPage;
export { AddNewPayment, EditPlan }